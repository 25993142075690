<template>
  <b-card :title="$t('level_progress')">
    <LevelProgress :points="profileData.points" />

  </b-card>
</template>

<script>
import LevelProgress from "./LevelProgress";
export default {
  name: "LevelDisplay",
  props: ["profileData"],
  components: {
    LevelProgress,
  },
};
</script>