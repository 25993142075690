<template>
  <div>
    <div class="d-flex" style="justify-content: space-between">
      <b>Level {{ level }}</b>
      <b>Level {{ level + 1 }}</b>
    </div>
    <b-progress class="mt-2" max="100">
      <b-progress-bar
        :value="pointProgress"
        variant="success"
      >
      <span>{{pointsGot}} / {{pointsNeeded}} {{$t('points')}}</span>
      </b-progress-bar>
    </b-progress>
    <br>
    {{$t('total')}} <b>{{points}}</b> {{$t('points_collected')}}
  </div>
</template>

<script>
export default {
  name: "LevelProgress",
  props: ["points"],
  created() {
      console.log(this);
  },
  computed: {
    level() {
      let levelId = 1;
      for (let pointLimit = 50; pointLimit < 1000000; pointLimit *= 1.5) {
        if (this.points < pointLimit) {
          return levelId;
        }
        levelId++;
      }
      return levelId;
    },
    currentLevelPoints() {
      let lastPointLimit = 0;
      for (let pointLimit = 50; pointLimit < 1000000; pointLimit *= 1.4) {
        if (this.points < pointLimit) {
          return lastPointLimit;
        }
        lastPointLimit = pointLimit;
      }
      return lastPointLimit;
    },
    nextLevelPoints() {
      for (let pointLimit = 50; pointLimit < 1000000; pointLimit *= 1.4) {
        if (this.points < pointLimit) {
          return pointLimit;
        }
      }
      return Infinity;
    },
    pointsNeeded() {
        return Math.ceil(this.nextLevelPoints - this.currentLevelPoints);
    },
    pointsGot() {
      let lastPointLimit = 0;
      for (let pointLimit = 50; pointLimit < 1000000; pointLimit *= 1.3) {
        if (this.points < pointLimit) {
          return (this.points - lastPointLimit) | 0;
        }
        lastPointLimit = pointLimit;
      }
      return this.points - lastPointLimit;
    },
    pointProgress() {
        return (this.pointsGot / this.pointsNeeded) * 100;
    }
  },
};
</script>