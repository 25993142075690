<template>
  <b-nav-item-dropdown right>
    <template #button-content>
      <flag :iso="localeFlagIso" :squared="false" />
    </template>
    <b-dropdown-item href="#" @click="setLocale('en')"
      ><flag iso="us" :squared="false" /> English
    </b-dropdown-item>
    <b-dropdown-item href="#" @click="setLocale('de')"
      ><flag iso="de" :squared="false" /> Deutsch
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "LanguageSelector",
  methods: {
    setLocale(locale) {
      i18n.locale = locale;
      document.title = i18n.t('title_long');
    },
  },
  computed: {
    localeId() {
      return i18n.locale;
    },
    localeFlagIso() {
      switch (i18n.locale) {
        case "en":
          return "us";
        default:
          return i18n.locale;
      }
    },
  },
};
</script>
