<template>
  <div>
    <b-modal :title="$t('privacy')" id="modal-privacy">
      <PrivacyTerms />
    </b-modal>

    <b-modal title="Nutzungsbedingungen" id="modal-usage">
      <UsageTerms />
    </b-modal>

    <p class="mb-0 mt-2">{{ $t("username") }}:</p>
    <b-form-input
      class="mt-0"
      v-model="input.username"
      maxlength="30"
      :placeholder="$t('your_username')"
    ></b-form-input>

    <p class="mb-0 mt-2">{{ $t("mail_address") }}:</p>
    <b-form-input
      class="mt-0"
      v-model="input.mail"
      maxlength="100"
      :placeholder="$t('your_mail_address')"
      trim
    ></b-form-input>

    <p class="mb-0 mt-2">{{$t('password')}}:</p>
    <b-form-input
      class="mt-0"
      v-model="input.password"
      maxlength="50"
      type="password"
      :placeholder="$t('your_password')"
      trim
    ></b-form-input>
    <small>{{$t('password_characters')}}</small>

    <p class="mb-0 mt-2">{{$t('repeat_password')}}:</p>
    <b-form-input
      class="mt-0"
      v-model="repeatPassword"
      maxlength="50"
      type="password"
      :placeholder="$t('repeat_your_password')"
      trim
    ></b-form-input>

    <!--
    <b-form-checkbox class="mt-3" v-model="acceptedTerms">
      Ich akzeptiere die
      <b class="text-info text-btn" v-b-modal.modal-usage
        >Nutzungsbedingungen</b
      >
    </b-form-checkbox>
    -->

    <br />

    <b-form-checkbox v-model="acceptedPrivacy">
      {{$t('i_accept_the')}}
      <b class="text-info text-btn" v-b-modal.modal-privacy
        >{{$t('data_privacy')}}</b
      >
    </b-form-checkbox>

    <b-badge variant="danger" v-if="error">
      {{ error }}
    </b-badge>
    <div
      style="justify-content: space-between; flex-wrap: wrap"
      class="d-flex mt-2"
      v-if="!registred"
    >
      <div></div>
      <b-btn variant="success" :disabled="!isValid" @click="submit()">
        <b-icon-check /> {{$t('submit_registration')}}
      </b-btn>
    </div>
    <h4 v-else>
      <b-badge variant="success" style="white-space: normal"
        >{{$t('registration_sent')}}</b-badge
      >
    </h4>
  </div>
</template>

<script>
import PrivacyTerms from "./PrivacyTerms";
import UsageTerms from "./UsageTerms";
import Client from "../../client/client";

export default {
  name: "RegisterForm",
  components: {
    PrivacyTerms,
    UsageTerms,
  },
  data: () => ({
    input: {
      mail: "",
      username: "",
      password: "",
    },
    repeatPassword: "",
    acceptedTerms: true,
    acceptedPrivacy: false,
    registred: false,
    error: undefined,
  }),
  computed: {
    usernameValid() {
      return this.input.username.length > 2 && this.input.username.length <= 30;
    },
    mailValid() {
      return (
        this.input.mail.length > 3 &&
        this.input.mail.length <= 50 &&
        this.input.mail.includes("@")
      );
    },
    passwordValid() {
      return (
        this.input.password.length >= 6 &&
        this.input.password.length <= 50 &&
        this.repeatPassword === this.input.password
      );
    },
    isValid() {
      return (
        this.usernameValid &&
        this.mailValid &&
        this.passwordValid &&
        this.acceptedTerms &&
        this.acceptedPrivacy
      );
    },
  },
  methods: {
    submit() {
      Client.applyRegistration(this.input).then((response) => {
        if (response.success) {
          this.registred = true;
          this.error = undefined;
        } else {
          this.registred = false;
          this.error =
            response.error[1] + " (Fehler: " + response.error[0] + ")";
          switch (response.error[0]) {
            case "mail-in-use":
              this.input.mail = "";
              break;
            case "username-in-use":
              this.input.username = "";
              break;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.text-btn {
  cursor: pointer;
}
</style>