<template>
  <b-dropdown text="Team wählen" variant="success" :size="size">
    <b-dropdown-item
      v-for="(team, index) of game.settings.teams"
      :key="index"
      :style="{ background: getTeamColor(index) }"
      @click="selectTeam(index)"
      >{{ team }} ({{ getTeamPlayerCount(index) }})</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
import Teams from "../../../../shared/teams";

export default {
  name: "TeamSelector",
  props: ["game","label","size"],
  methods: {
    getTeamPlayerCount(index) {
      let count = 0;
      for (let player of Object.values(this.game.playerList)) {
        if (player.team === index) {
          count++;
        }
      }
      return count;
    },
    getTeamColor(index) {
      return Teams.colors[index];
    },
    selectTeam(index) {
        this.$emit('select', index);
    }
  },
};
</script>