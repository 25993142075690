import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import i18n from '@/plugins/i18n';

import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

import 'bootstrap/dist/css/bootstrap.css'
import './assets/style/bootstrap_sketchy.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Ads from 'vue-google-adsense'



Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const isInternational = window.location.host === 'categories.cool';
const gtagId = isInternational ? "G-W33QFBEQ9E" : "G-EEC9L5YLS3";

console.log("International:", isInternational);

console.log(gtagId);

import VueGtag from "vue-gtag";
if (window.location.host !== 'localhost') {
  Vue.use(VueGtag, {
    config: { id: gtagId }
  });
}


/**
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://d39319212a984da09021c81bb8f8aa92@o530975.ingest.sentry.io/5651080",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "stadtlandfluss.cool", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});
*/

Vue.config.productionTip = false

new Vue({
  i18n,
  render: function (h) { return h(App) },
}).$mount('#app')