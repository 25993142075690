<template>
  <div class="toggle-switch">
    <span class="title">{{ label }}:</span>
    <span class="switch" @click="toggle()" :class="{disabled}">
      <div
        class="switch-bar"
        :class="{ 'bar-active': toggled }"
      ></div>
      <div
        class="switch-handle"
        :class="{'bg-secondary': disabled, 'bg-info': !toggled && !disabled, 'bg-success': toggled && !disabled, 'handle-active': toggled }"
      ></div>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: ["label", "state", "disabled"],
  data: () => {
    return {
      selfToggled: false,
    };
  },
  computed: {
    toggled() {
      return this.state || this.selfToggled;
    },
  },
  methods: {
    toggle() {
      if(!this.disabled) {
        this.selfToggled = !this.selfToggled;
        this.$emit("toggle", this.selfToggled);
      }
    },
  },
};
</script>

<style scoped>
.toggle-switch {
  display: flex;
}
.disabled {
  cursor: default !important;
}
.switch {
  margin-left: 0.5em;
  height: 1em;
  width: 2em;
  cursor: pointer;
  position: relative;
}
.switch-handle {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  left: 0;
  transition: 200ms;
}
.handle-active {
    left: 1em;
}
.switch-bar {
  width: 100%;
  height: 0.4em;
  background-color: rgba(220,220,220);
  position: absolute;
  top: 0.35em;
  border-radius: 50%;
  z-index: 1;
}
</style>
