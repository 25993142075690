<template>
  <b-card class="vote-results mb-2">
    <b-modal id="exportModal" title="Grafik exportieren" ref="exportModal">
      <div class="d-flex">
        <GraphicVoteScore ref="graphicVoteScore" />
        <b-button
          variant="success"
          style="max-height: 36px"
          class="ml-1"
          @click="saveGraphic()"
        >
          <b-icon-download /> Speichern
        </b-button>
      </div>
      <template slot="modal-footer">
        <b-button @click="$refs.exportModal.hide()"> Fertig </b-button>
      </template>
    </b-modal>
    <template #header>
      <h5 style="float: left"><b-icon-card-checklist /> {{ $t("score") }}</h5>
      <b-dropdown
        variant="success"
        style="float: right"
        text="Sortierung"
        v-if="game.settings.teamsEnabled"
      >
        <b-dropdown-item @click="showTeams = true"
          ><b-icon-people /> Teams</b-dropdown-item
        >
        <b-dropdown-item @click="showTeams = false"
          ><b-icon-person /> Spieler</b-dropdown-item
        >
      </b-dropdown>
    </template>
    <div class="score-list" :style="{ height: scoreList.length * 5 + 'em' }">
      <template v-if="game.settings.teamsEnabled && showTeams">
        <div v-for="(team, index) of getTeamList()" :key="index">
          <h6
            class="my-2 py-1 px-1"
            :style="{ background: getTeamColor(team.index) }"
          >
            <b>{{ team.name }}</b
            >: {{ getTeamPoints(team.index) }} Punkte
          </h6>
          <ScoreListEntry
            v-for="player of getTeamPlayers(team)"
            :key="player.name"
            :name="player.name"
            :image="player.userInfo.image"
            :points="player.points"
            :place="getPlace(player)"
            :newPoints="getNewScore(player)"
            :player="player"
            :final="final"
          />
        </div>
      </template>
      <template v-else>
        <ScoreListEntry
          v-for="(player, index) of scoreList"
          :key="player.player.name"
          :name="player.player.name"
          :image="player.player.userInfo.image"
          :points="player.score"
          :place="index + 1"
          :newPoints="player.newScore"
          :player="player.player"
        />
      </template>
    </div>
    <slot />
  </b-card>
</template>

<script>
import PointCalculator from "../../../../shared/points";
import ScoreListEntry from "./ScoreListEntry";
const Teams = require("../../../../shared/teams");
import Client from "../../client/client";
import GraphicVoteScore from "./GraphicVoteScore";

export default {
  components: { ScoreListEntry, GraphicVoteScore },
  name: "VoteScore",
  props: ["game", "final", "live"],
  data: () => ({
    showTeams: true,
  }),
  created() {
    if (this.final) {
      if (Client.session !== undefined && !this.isAlone()) {
        console.log("Is alone", this.isAlone(), this.scoreList.length);
        Client.addStats(
          this.game.playerProfile.points,
          this.getPlace(this.game.playerProfile),
          0
        );
      }
    }
  },
  computed: {
    scoreList() {
      const list = Object.values(this.game.playerList);
      const scoreList = [];
      for (const player of list) {
        if (player !== undefined) {
          const score = this.getScoreFor(player);
          if (score !== undefined) {
            scoreList.push(score);
          }
        }
      }
      scoreList.sort((a, b) => {
        if (a.score > b.score) {
          return -1;
        } else {
          return 1;
        }
      });
      return scoreList;
    },
  },
  methods: {
    getScoreFor(player) {
      const newScore = this.getNewScore(player);
      if (player.conn === "on" || newScore > 0 || player.points > 0) {
        if (this.live) {
          return {
            score: newScore + player.points,
            newScore,
            player,
          };
        } else {
          return { score: player.points, newScore, player };
        }
      } else {
        return undefined;
      }
    },
    getTeamPlayers(team) {
      return team.players.filter(
        (player) => player !== undefined && player.userInfo !== undefined
      );
    },
    isAlone() {
      return this.scoreList.length <= 1;
    },
    showExport() {
      this.$refs.exportModal.show();
      setTimeout(() => {
        this.renderExport();
      }, 1000);
    },
    renderExport() {
      let lines = [];
      lines.push(["headline", { text: "Ergebnis" }]);
      if (this.game.settings.teamsEnabled) {
        for (const team of this.getTeamList()) {
          lines.push([
            "team",
            {
              name: team.name,
              points: this.getTeamPoints(team.index),
              color: this.getTeamColor(team.index),
            },
          ]);
          for (const player of team.players) {
            lines.push([
              "player",
              {
                name: player.name,
                score: player.points,
                newScore: this.getNewScore(player),
              },
            ]);
          }
        }
      }
      this.$refs.graphicVoteScore.reset(lines.length);
      for (const line of lines) {
        this.$refs.graphicVoteScore.render(line[0], line[1]);
      }
      this.$refs.graphicVoteScore.done();
    },
    saveGraphic() {
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "Ergebnis.png");
      let dataURL = this.$refs.graphicVoteScore.getImageData();
      let url = dataURL.replace(
        /^data:image\/png/,
        "data:application/octet-stream"
      );
      downloadLink.setAttribute("href", url);
      downloadLink.click();
    },
    getPlace(player) {
      let place = 1;
      let score = this.getScoreFor(player);
      if (score !== undefined) {
        for (let p of Object.values(this.game.playerList)) {
          if (player !== p) {
            const score = this.getScoreFor(p);
            if (score !== undefined && score > score.score) {
              place++;
            }
          }
        }
        return place;
      } else {
        return Object.values(this.game.playerList).length;
      }
    },
    getTeamPoints(index) {
      let scoreCount = 0;
      for (let player of Object.values(this.game.playerList)) {
        if (player.team === index) {
          scoreCount += player.points;
        }
      }
      console.log("TOta", scoreCount);
      return scoreCount;
    },
    getTeamList() {
      const list = [];
      for (let player of Object.values(this.game.playerList)) {
        if (!list.includes(player.team)) {
          list.push(player.team);
        }
      }
      const teamPoints = [];
      let index = 0;
      for (let team of list) {
        teamPoints.push({
          index: team,
          points: 0,
          players: [],
          name: this.game.settings.teams[team],
        });
        for (let player of Object.values(this.game.playerList)) {
          if (player.team === team) {
            teamPoints[index].points += player.points;
            teamPoints[index].players.push(player);
          }
        }
        index++;
      }
      const sortedTeams = [];
      for (let i = 0; i < list.length; i++) {
        let biggest = teamPoints[0];
        for (let team of teamPoints) {
          if (team.points > biggest.points) {
            biggest = team;
          }
        }
        sortedTeams.push(biggest);
        teamPoints.splice(teamPoints.indexOf(biggest), 1);
      }
      return sortedTeams;
    },
    getTeamColor(index) {
      return Teams.colors[index];
    },
    getNewScore(player) {
      if (
        this.game.roundResults.answers &&
        this.game.roundResults.answers[player.id]
      ) {
        return PointCalculator.calculateTotalPoints(
          this.game.settings.points,
          this.game.roundResults.answers,
          player.id,
          this.getNeededToReject()
        );
      } else {
        return 0;
      }
    },
    getAvatar(player) {
      return require("../../assets/avatars/" + player.avatar + ".png");
    },
    getNeededToReject() {
      return PointCalculator.calculateRejectThreshold(
        this.game.onlinePlayerCount(),
        this.game.settings.neededToReject
      );
    },
  },
};
</script>

<style scoped>
.profile-img {
  position: relative;
  margin-right: 1em;
}
.profile-img > img {
  width: 3em;
}
.profile-img > div {
  font-size: 2em;
  color: white;
  text-shadow: 0 0 0.5em black;
  position: absolute;
  top: 0.1em;
  left: 0;
  text-align: center;
  width: 100%;
}
.score-list-entry {
  width: 100%;
  left: 0;
  height: 3em;
  transition: 500ms;
}
.score-list {
  position: relative;
}
</style>
