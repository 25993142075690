<template>
  <b-modal ref="modal" :title="$t('edit_avatar')">
    <template v-if="visible">
      <AvatarCustomizer
        :avatarData="avatar"
        :noCache="true"
        @avatarChanged="avatar = $event"
        @save="$emit('input', avatar); hide();"
      />
      <br />
      <p class="text-muted">{{ $t("avatar_login_hint") }}</p>
    </template>
    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
import AvatarCustomizer from "./AvatarCustomizer.vue";

export default {
  name: "AvatarEditDialog",
  data: () => ({
    avatar: undefined,
    visible: false,
  }),
  components: {
    AvatarCustomizer,
  },
  methods: {
    show(avatar) {
      this.avatar = avatar;
      this.visible = true;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.visible = false;
    },
  },
};
</script>