<template>
  <b-modal
    ref="word-catalog"
    scrollable
    id="word-catalog"
    :title="$t('more_suggestions')"
  >
    <div v-for="letter in letterList" :key="letter">
      <template v-if="getWordsForLetter(letter).length > 0">
        <h5>{{ letter }}</h5>
        <b-row>
          <span
            v-for="word in getWordsForLetter(letter)"
            :key="word"
            class="word"
            @click="select(word)"
          >
            <b-badge variant="info">
              {{ word }}
            </b-badge>
          </span>
        </b-row>
        <hr />
      </template>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" class="float-right" @click="hide()">
          {{ $t("close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import i18n from "@/plugins/i18n";
export default {
  name: "WordCatalog",
  props: ["game"],
  data: () => {
    return {
      suggested_words: {
        de: {
          A: ["Auf dem Bauernhof", "Anmachspruch"],
          C: ["Chemisches Element", "Cocktail", "Computerspiel"],
          D: ["Darf im Kühlschrank nie fehlen", "Dinoart"],
          E: [
            "Erhöht die Coolness",
            "Erfundener Beruf",
            "Etwas ekeliges",
            "Eissorte",
            "Englisches Wort",
            "Elektrisches Gerät",
          ],
          F: ["Fabelwesen", "Farbe", "Fortbewegungsmittel"],
          G: [
            "Grabsteininschrift",
            "Größer als ein Elefant",
            "Grund für Verspätung",
            "Grund zum Heulen",
            "Grund für Schulverweis",
            "Gegenstand auf dem Schreibtisch",
          ],
          K: ["Kleiner als eine Ameise", "Krankheit"],
          L: ["Letzte Worte"],
          N: [
            "Namen den man keinem Kind geben sollte",
            "No-Go beim ersten Date",
            "Naturkatastrophe",
          ],
          P: ["Pizzabelag"],
          R: ["Rappername"],
          S: ["Senkt die Coolness", "Sehenswürdigkeit", "Superkraft", "Sollte man nicht essen", "Sollte man nicht auf der Toilette tun"],
          T: ["Tanzart", "Tut weh"],
          W: ["Werbeslogan", "Wort aus einer Fremdsprache"],
        },
        en: {
          A: ["Advertising slogan"],
          B: ["Bigger than an elephant"],
          C: ["Chemical element", "Cocktail"],
          D: ["Dance style"],
          E: ["Electric device"],
          F: ["Foreign word"],
          G: ["Gravestone inscription"],
          H: ["Hurts"],
          I: ["Increases coolness", "Imaginary job", "Ice cream"],
          L: ["Last words", "Lowers the coolness"],
          N: [
            "Names that shouldn't be given to a child",
            "No-go on the first date",
            "Natural disaster",
          ],
          M: [
            "Must never be missing in the refrigerator",
            "Means of transportation",
            "Mythical creature",
          ],
          O: ["On the farm", "On the desk"],
          P: ["Pizza topping", "Pick-up line"],
          R: [
            "Rapper name",
            "Reason for delay",
            "Reason to cry",
            "Reason for expulsion from school",
          ],
          S: [
            "Landmark",
            "Superpower",
            "Something gross",
            "Smaller than an ant",
          ],
        },
      },
    };
  },
  computed: {
    letterList() {
      return Object.keys(this.suggested_words[i18n.locale]);
    },
  },
  methods: {
    show() {
      this.$refs["word-catalog"].show();
    },
    hide() {
      this.$refs["word-catalog"].hide();
    },
    getWordsForLetter(letter) {
        const list = [];
        for(const word of this.suggested_words[i18n.locale][letter]) {
            if(!this.game.settings.words.includes(word)) {
                list.push(word);
            }
        }
      return list;
    },
    select(word) {
      this.$emit("select", word);
    },
  },
};
</script>

<style scoped>
.word {
  margin: 0.1em;
  cursor: pointer;
}
</style>
