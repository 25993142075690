<template>
  <b-card class="game-input">
    <template #header>
      <h5 style="float: left"><b-icon-pencil /> {{ $t("game_input") }}</h5>
      <h5 style="float: right" class="my-0">
        <b-badge
          variant="success"
          @click="newLetter()"
          :class="{ clickable: game.playerProfile.admin }"
          v-b-tooltip.hover
          :title="game.playerProfile.admin ? $t('change_letter') : undefined"
        >
          {{ $t("letter") }}: {{ game.round.letter }}
          <b-icon-arrow-repeat v-if="game.playerProfile.admin" />
        </b-badge>
      </h5>
    </template>
    <b-input-group
      v-for="word of game.settings.words"
      :key="word"
      size="lg"
      :prepend="word"
      class="mb-1"
    >
      <template #append>
        <b-button
          :variant="wordsConfirmed[word] ? 'success' : 'secondary'"
          @click="setWordConfirmed(word)"
          title="Bestätigen"
          v-b-tooltip
          :disabled="wordsConfirmed[word]"
          ><b-icon-check
        /></b-button>
      </template>
      <b-form-input
        :placeholder="word + ' ' + $t('word_with') + ' ' + game.round.letter"
        @focus="onWordFocus(word)"
        @blur="onWordBlur(word)"
        @keydown="wordInputKeydown($event, word)"
        autocomplete="off"
        :ref="`input-${word}`"
        :disabled="isDone"
        :class="{ 'input-valid': getAnswerValid(word) }"
        class="word-input"
        maxlength="400"
        v-model="words[word]"
      ></b-form-input>
    </b-input-group>
    <hr />
    <b><b-icon-clock /> {{ $t("remaining_time") }}:</b>
    <TimeBar :game="game" />
    <hr />
    <b-button
      class="done-button hcenter"
      v-if="game.settings.roundStop === 'stop'"
      :class="{ 'done-button-hover': !isDone }"
      :variant="isDone ? 'success' : 'danger'"
      @click="markDone()"
      :disabled="isDone"
    >
      <b-icon-check v-if="isDone" />
      <b-icon-hand-thumbs-down v-else class="hand-icon" /> {{ $t("done") }}
      <template
        v-if="game.settings.stopCount > 1 && game.round.stopped !== undefined"
        >({{
          game.round.stopped.length + "/" + game.settings.stopCount
        }})</template
      >
    </b-button>
  </b-card>
</template>

<script>
import i18n from "../../plugins/i18n";
import TimeBar from "./TimeBar";
export default {
  name: "GameInput",
  props: ["game"],
  components: {
    TimeBar,
  },
  data: () => ({
    wordsConfirmed: {},
    words: {},
  }),
  computed: {
    isDone() {
      return (
        this.game.round.stopped &&
        this.game.round.stopped.includes(this.game.playerProfile.id)
      );
    },
    answers() {
      return this.game.wordInput;
    },
  },
  mounted() {
    this.game.wordInput = {};
  },
  methods: {
    setWordConfirmed(word) {
      if (this.words[word] && this.words[word].length >= 2) {
        this.wordsConfirmed[word] = true;
        this.game.wordInput[word] = this.words[word];
        this.$forceUpdate();
      }
    },
    getAnswerValid(word) {
      return (
        this.answers[word] &&
        this.answers[word].length >= 2 &&
        this.answers[word].charAt(0).toUpperCase() === this.game.round.letter
      );
    },
    focusField(index) {
      const elem = this.$refs["input-" + this.game.settings.words[index]];
      if (elem && elem[0]) {
        elem[0].focus();
      }
    },
    wordInputKeydown(event, word) {
      this.wordsConfirmed[word] = false;
      if (this.game.wordInput[word]) {
        delete this.game.wordInput[word];
      }
      if (event.key === "Enter" || event.key === "ArrowDown") {
        let newIndex = this.game.settings.words.indexOf(word) + 1;
        if (newIndex < this.game.settings.words.length) {
          this.focusField(newIndex);
        }
      } else if (event.key === "ArrowUp") {
        let newIndex = this.game.settings.words.indexOf(word) - 1;
        if (newIndex >= 0) {
          this.focusField(newIndex);
        }
      }
    },
    created() {
      this.game.wordInput = {};
    },
    getAmountDone() {
      let count = 0;
      for (const word of this.game.settings.words) {
        if (this.getAnswerValid(word)) {
          count++;
        }
      }
      return count;
    },
    onWordFocus(word) {
      this.game.updateProgress(this.getAmountDone(), word);
    },
    onWordBlur(word) {
      this.game.updateProgress(this.getAmountDone(), undefined);
      if(!this.words[word]) {
        console.error("Word",word,"undef in wordblur / GameInput.vue. Words:", this.words);
      }
      if (this.words[word] && this.words[word].length >= 2) {
        this.setWordConfirmed(word);
      }
    },
    newLetter() {
      if (this.game.playerProfile.admin) {
        this.game.requestNewLetter();
      }
    },
    markDone() {
      if (this.getAmountDone() === this.game.settings.words.length) {
        this.game.markDone();
      } else {
        this.$confirm(
          i18n.t("not_all_words"),
          i18n.t("are_you_sure"),
          "question",
          {
            confirmButtonText: i18n.t("done"),
            cancelButtonText: i18n.t("abort"),
          }
        )
          .then(() => {
            this.game.markDone();
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.word-input {
  transition: 300ms;
}
.input-valid {
  background-color: greenyellow;
}
.done-button {
  width: 10em;
  height: 3em;
  transform-origin: center;
  transition: 200ms;
}
.done-button-hover:hover {
  transform: scale(1.1) translateX(-50%);
}
.done-button > .hand-icon {
  transition: 300ms;
}
.done-button:hover > .hand-icon {
  transform: rotate(180deg);
}
</style>
