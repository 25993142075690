<template>
  <b-modal ref="forgotPasswordDialog" :title="$t('reset_password')">
    <template v-if="sent">
      <p>{{ $t("reset_success") }}</p>
    </template>
    <template v-else>
      <p>{{ $t("password_mail") }}</p>
      <b-form-input
        v-model="mail"
        placeholder="E-Mail"
        @keydown.enter="send()"
      ></b-form-input>
    </template>
    <div slot="modal-footer">
      <b-btn class="btn-info" v-if="sent" @click="done()">
        {{ $t("done") }}
      </b-btn>
      <b-btn class="btn-success" v-else :disabled="!mailValid" @click="send()">
        {{ $t("continue") }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import Client from "../../client/client";

export default {
  name: "ForgotPasswordDialog",
  data: () => ({
    mail: "",
    sent: false,
  }),
  methods: {
    show() {
      this.$refs.forgotPasswordDialog.show();
    },
    hide() {
      this.$refs.forgotPasswordDialog.hide();
    },
    done() {
      this.hide();
      this.sent = false;
    },
    send() {
      if (this.mailValid) {
        Client.requestPasswordReset(this.mail).then((response) => {
          console.log(response);
          if (response.success) {
            this.sent = true;
          } else if (response.error === "already-requested") {
            this.$alert(
              this.$i18n.t("reset_already_requested"),
              this.$i18n.t("error"),
              "error"
            );
            this.hide();
          }
        });
      }
    },
  },
  computed: {
    mailValid() {
      return this.mail.length > 0 && this.mail.split("@").length === 2;
    },
  },
};
</script>