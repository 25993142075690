<template>
  <b-list-group-item class="dropdown-item" @click="$emit('select')">
      <slot />
  </b-list-group-item>
</template>

<script>

export default {
  name: "DropdownItem"
};
</script>

<style scoped>
.dropdown-item {
    cursor: pointer;
}
.dropdown-item:hover {
    background-color: var(--primary)
}
</style>
