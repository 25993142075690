<template>
  <b-modal ref="modal" :title="$t('delete_account')">
    <p>{{ $t("delete_confirm_password") }}</p>
    <b-form-input
      class="mt-0"
      @keydown.enter="confirm()"
      v-model="pass"
      type="password"
      :placeholder="$t('new_password')"
      trim
    ></b-form-input>
    <template slot="modal-footer">
      <b-btn @click="confirm()" :disabled="!valid" variant="danger">
        <b-icon-trash /> {{ $t("delete_account") }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import Client from "../../client/client";

export default {
  name: "DeleteAccountDialog",
  data: () => ({
    pass: "",
  }),
  computed: {
    valid() {
      return this.pass.length >= 6;
    },
  },
  methods: {
    show() {
      console.log("Show");
      this.$refs.modal.show();
    },
    confirm() {
      if (this.valid) {
        Client.deleteAccount(this.pass).then(response => {
            console.log(response)
            if(response.success) {
                this.$alert(this.$i18n.t('account_deleted'), '', 'success');
                Client.setSession(undefined);
            } else if(response.error === 'invalid-pass') {
              this.$alert(this.$i18n.t('invalid_password'), this.$i18n.t('error'), 'error');
            }
        });
      }
    },
  },
};
</script>