<template>
  <div class="bg bg-1">
    <div class="center-overlay">
      <h2>{{ $t("connecting") }}</h2>
      <div class="rotate-icon hcenter">
        <b-icon-app-indicator />
      </div>
      <b-button variant="danger" class="hcenter mt-3" @click="abortLoad()">
        {{ $t("abort") }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConnectingPage",
  methods: {
    abortLoad() {
      this.$parent.exitGame();
    },
    test() {
      this.$parent.game.refresh();
    },
  },
};
</script>
<style scoped>
.center-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 1em 1em 2em lightgray;
}

.rotate-icon {
  animation: rotation linear infinite 1s;
  width: fit-content;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
