<template>
  <b-card :title="$t('game_history')">
    {{ $t('game_history_label') }}
    <div v-if="profileData.games && profileData.games.length <= 0">{{ $t('history_empty') }}</div>
    <b-row v-if="profileData.games">
      <b-col cols="8" style="min-width: 300px;">
        <PointChartDisplay :profileData="profileData" />
        <div class="text-center text-muted">{{$t('points_per_game')}}</div>
      </b-col>
      <b-col cols="4" style="min-width: 300px;">
        <RankChartDisplay :profileData="profileData" />
        <div class="text-center text-muted">{{ $t('ranks') }}</div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import PointChartDisplay from "./PointChartDisplay";
import RankChartDisplay from "./RankChartDisplay";

export default {
  name: "GameHistory",
  props: ["profileData"],
  components: {
    PointChartDisplay,
    RankChartDisplay,
  },
};
</script>