<template>
<div class="bg bg-4">
      <NavBar :items="nav" @select="onMenuAction($event)">
        <b-btn variant="success" @click="logout()"><b-icon-door-closed /> {{$t('logout')}}</b-btn>
    </NavBar>
    <b-row class="mt-4 mx-2">
        <b-col cols="12" lg="4" style="min-width: 320px;">
            <ProfileDisplay :profileData="profileData" @createGame="$parent.auth='create'; $parent.showUserProfile=false;"/>
        </b-col>
        <b-col cols="12" lg="8" style="min-width: 320px;">
            <LevelDisplay :profileData="profileData" class="mb-4" />
            <GameHistory :profileData="profileData" />
        </b-col>
    </b-row>
    <br>
    <Footer/>
</div>
</template>

<script>
import ProfileDisplay from "../components/profile/ProfileDisplay";
import LevelDisplay from "../components/stats/LevelDisplay";
import GameHistory from "../components/stats/GameHistory";
import Client from "../client/client";
import NavBar from "../components/generic/NavBar";
import Footer from "../components/home/Footer.vue";

export default {
  name: "ProfilePage",
  props: ['profileData'],
  components: {
      NavBar,
      ProfileDisplay,
      LevelDisplay,
      GameHistory,
      Footer
  },
  methods: {
      logout() {
          Client.setSession(undefined);
      },
      onMenuAction(item) {
          switch(item.action) {
              case 'home':
                  this.$parent.showUserProfile = false;
                  break;
          }
      },
  },
  computed: {
      nav() {
          return [{
              icon: 'house-door',
              label: this.$i18n.t('homepage'),
              action: 'home'
          }]
      }
  }
};
</script>