<template>
  <div
    class="avatar-edit-button"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="$emit('advance', property)"
  >  
    <b-icon-bullseye style="color: white"/>
    <div class="property-info">
      <div class="property-label">{{ $t(property) }}</div>
      <div class="property-counter">
          {{id}}/{{total}}
      </div>
    </div>
  </div>
</template>

<script>
const AvatarData = require("../../../../../shared/avatar");

export default {
  name: "AvatarPropertyEdit",
  props: ["property", "avatar"],
  data: () => ({
    hover: false
  }),
  computed: {
      total() {
          return AvatarData.props[this.property].length;
      },
      id() {
          return AvatarData.props[this.property].indexOf(this.avatar[this.property]) + 1;
      }
  }
};
</script>

<style scoped>
.avatar-edit-button {
  cursor: pointer;
}
.avatar-edit-button:hover > .hand-icon {
  opacity: 0.8;
  transform: scale(1.5);
}
.avatar-edit-button:hover > .property-info {
    opacity: 1;
}
.hand-icon {
  transition: 300ms;
  opacity: 0.5;
  filter: drop-shadow(0 0 2px black);
}
.property-info {
    opacity: 0;
    pointer-events: none;
    transition: 300ms;
    background-color: rgba(255,255,255,0.5);
    color: black;
    position: absolute;
    top: 2.5em;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;
    text-align: center;
    padding: .5em;
    padding-top: 1.5em;
    border-radius: .5em;
    clip-path: polygon(0 20%, 25% 20%, 50% 0, 75% 22%, 100% 20%, 100% 100%, 0 100%);
}
.property-label {
    font-weight: bold;
}

</style>

