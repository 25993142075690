<template>
  <div class="settings">
    <CollapsePanel ref="wordsPanel" :header="$t('select_categories')" :collapsedDefault="false" @open="$refs.settings.collapse()">
      <b-card-body>
      <WordSelector :game="game" />
      <hr v-if="isAdmin">
        <ToggleSwitch v-if="isAdmin" :label="$t('players_can_add_words')" :state="game.settings.words_free" @toggle="game.setSetting('words_free', $event)"/>
      </b-card-body>
    </CollapsePanel>
    <AdvancedSettings ref="settings" :game="game" @open="$refs.wordsPanel.collapse()"/>
  </div>
</template>

<script>
import WordSelector from "./WordSelector";
import ToggleSwitch from "../generic/ToggleSwitch";
import AdvancedSettings from "./AdvancedSettings";
import CollapsePanel from "../generic/panel/CollapsePanel";

export default {
  name: "Settings",
  props: ["game"],
  components: {
    WordSelector,
    ToggleSwitch,
    AdvancedSettings,
    CollapsePanel
  },
  computed: {
      isAdmin() {
          return this.game.playerProfile.admin;
      }
  }
};
</script>

<style scoped>
</style>
