<template>
  <div
    :class="wrapperSizeClass"
    class="wrapper"
    @click.stop="onClick($event)"
    @mouseleave="mouseIn = false"
  >
    <div
      v-if="image.type === 'image'"
      class="content"
      :class="contentSizeClass"
      :style="contentStyle"
    >
      <img :src="imageSrc" />
    </div>
    <div
      v-if="image.type === 'avatar'"
      class="content"
      :class="contentSizeClass"
      :style="contentStyle"
    >
      <AvatarDisplay :avatar="image.avatar" :circle="true" />
    </div>
    <slot class="slot-content"/>
  </div>
</template>

<script>
import AvatarDisplay from "./avatar/AvatarDisplay";
import Client from "../../client/client";

export default {
  name: "ProfileImageDisplay",
  props: ["image", "size", "hoverable", "player"],
  data: () => ({
    mouseIn: false,
    clickPos: { x: 0, y: 0 },
  }),
  components: {
    AvatarDisplay,
  },
  computed: {
    imageSrc() {
      if (this.image.type === "image") {
        return `images/${this.player ? this.player.name : Client.app.profileData.username}.jpg?d=${
          Date.now() / 1000
        }`;
      }
    },
    wrapperSizeClass() {
      if (this.size) {
        return this.size;
      }
    },
    contentSizeClass() {
      if (this.hoverable && this.mouseIn) {
        return "hover-expand";
      }
      if (this.size === "icon" || this.size === "small-icon") {
        return "circle";
      }
    },
    contentStyle() {
      if (this.mouseIn) {
        return {
          top: `${this.clickPos.y}px`,
          left: `${this.clickPos.x}px`,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    onClick(event) {
      if (this.mouseIn) {
        this.mouseIn = false;
      } else {
        this.clickPos.x = event.clientX;
        this.clickPos.y = event.clientY;
        if (this.clickPos.x < 128) this.clickPos.x = 128;
        this.mouseIn = true;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
img {
  box-shadow: 0 0 0.5em gray;
  width: 100%;
  height: 100%;
  max-width: 256px;
  max-height: 256px;
}
.icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.small-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.large-icon {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.tiny {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
.icon img {
  box-shadow: none;
  width: 3rem;
  height: 3rem;
}
.small-icon img {
  box-shadow: none;
  width: 2rem;
  height: 2rem;
}
.large-icon img {
  box-shadow: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.content {
  transition: 300ms;
  z-index: 99;
}
.hover-expand {
  position: fixed;
  width: 256px;
  height: 256px;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5em black;
  outline: 1rem solid white;
  background-color: white;
}
.hover-expand img {
  width: 256px !important;
  height: 256px !important;
}
.circle > * {
  border-radius: 50% !important;
}
.slot-content {
  position: absolute;
  top: 0;
  left: 0;
}
</style>