<template>
  <div class="bg bg-2">
    <NavBar
      :title="$t('title')"
      :items="menuItems"
      @select="menuSelect($event)"
    />
    <PingView :ping="game.ping" />
    <PageTitle :text="$t('lobby')" />
    <LinkShare :game="game" />
    <b-row class="mx-0">
      <b-col class="widget px-0">
        <div class="card">
          <div class="card-header">
            <h4 style="float: left"><b-icon-gear /> {{ $t("game") }}</h4>
            <b-button
              variant="success"
              style="float: right"
              v-if="game.playerProfile.admin"
              @click="startGame()"
            >
              <b-icon-play /> {{ $t("start_game") }}
            </b-button>
          </div>
          <div class="card-body mobile-nopadding">
            <Settings :game="game" />
          </div>
        </div>
      </b-col>
      <b-col class="widget px-0">
        <div class="card">
          <div class="card-header">
            <h4 style="float: left;">
              <b-icon-people /> {{ $t("players") }} ({{ onlinePlayerCount }})
            </h4>
            <TeamSelector v-if="game.settings.teamsEnabled && game.settings.teamsUserSelect" :game="game" style="float: right" @select="selectTeam($event)"/>
          </div>
          <div class="card-body mobile-nopadding">
            <PlayerList :game="game" />
          </div>
        </div>
      </b-col>
    </b-row>
    <Chat :game="game" />
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import PingView from "../components/generic/PingView";
import PageTitle from "../components/generic/PageTitle";
import PlayerList from "../components/lobby/PlayerList";
import Settings from "../components/settings/Settings";
import Chat from "../components/generic/Chat";
import LinkShare from "../components/lobby/LinkShare";
import TeamSelector from "../components/lobby/TeamSelector";
import i18n from "../plugins/i18n";

export default {
  name: "LobbyPage",
  props: ["game"],
  data: () => {
    return {
      menuItems: [
        {
          label: "exit_game",
          icon: "box-arrow-left",
          action: "exit",
        },
      ],
    };
  },
  components: {
    NavBar,
    PingView,
    PageTitle,
    PlayerList,
    Chat,
    Settings,
    LinkShare,
    TeamSelector
  },
  computed: {
    onlinePlayerCount() {
      let count = 0;
      for (const id in this.game.playerList) {
        if (this.game.playerList[id].conn === "on") {
          count++;
        }
      }
      return count;
    }
  },
  methods: {
    menuSelect(item) {
      switch (item.action) {
        case "exit":
          this.exit();
          break;
      }
    },
    exit() {
      this.$parent.exitGame();
    },
    selectTeam(index) {
      this.game.selectTeam(this.game.playerProfile.id, index);
    },
    startGame() {
      if (this.game.settings.words.length <= 0) {
        this.$alert(i18n.t("no_categories"), i18n.t("error"), "error");
      } else if (this.game.onlinePlayerCount() < 2) {
        this.$confirm(
          i18n.t("start_game_no_players"),
          i18n.t("start_game"),
          "question",
          {
            cancelButtonText: i18n.t("abort"),
            confirmButtonText: i18n.t("continue"),
          }
        ).then(() => {
          this.game.start();
        }).catch(() => {
          
        });
      } else {
        this.game.start();
      }
    },
  },
};
</script>
<style scoped>
.widget {
  min-width: 250px;
  max-width: 100vw;
  margin: 1em;
}
@media (max-width: 670px) {
  .widget {
    margin-top: 1em;
    margin-right: 0;
    margin-left: 0;
  }
  .mobile-nopadding {
    padding-left: 0.1em;
    padding-right: 0.1em;
  }
}
</style>
