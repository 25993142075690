<template>
  <b-dropdown style="max-height: 56px;" variant="outline-info">
    <b-dropdown-item @click="$emit('deleteAccount')"><b-icon-trash /> {{$t('delete_account')}}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
    name: 'ProfileSettingsDropdown'
}
</script>