<template>
  <div>
    <div id="splash-screen" class="bg bg-1">
      <NavBar
        :title="$t('title')"
        :items="menuItems"
        @select="menuSelect($event)"
      >
        <b-btn
          variant="success"
          v-if="$parent.session === undefined"
          @click="showLogin()"
          ><b-icon-person /> {{ $t("login") }}</b-btn
        >
        <div v-else>
          <ProfileImageDisplay
            @click.native="$parent.showUserProfile = true"
            v-if="$parent.profileData.image"
            class="mr-1"
            size="icon"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="Profil"
            :image="$parent.profileData.image"
          />
        </div>
      </NavBar>
      <div
        id="splash-screen-content"
        :class="{ 'splash-screen-content-list': gameListVisible }"
      >
        <!--
      <a href="https://appointa.net" target="_blank" @click="openAppointa()">
        <img class="advertisement" src="../assets/appointa_banner.webp">
      </a>
    -->
        <div id="main-title">
          <div id="main-title-icons">
            <img src="@/assets/img/title/rural.png" />
            <img src="@/assets/img/title/urban.png" />
            <img src="@/assets/img/title/river.png" />
          </div>
          <img class="title-image" :src="titleImage" />
          <div id="main-title-buttons">
            <!--
            <b-button variant="success" v-b-modal.modal-gamecreator>
              <b-icon-plus /> {{ $t("create_game") }}
            </b-button>
            -->
            <!--
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Aktuell: Serverwartung. Ab 17 Uhr sind wieder Spiele möglich. Bis dahin kannst du auf stadtlandfluss.cool spielen :)"
              class="mt-1"
            >
            -->
            <!---->
            <b-button
              variant="info"
              @click="openMainenanceFallback()"
              class="mt-1"
              v-if="maintenance"
              v-b-tooltip.hover
              :title="$t('maintenance_message')"
            >
              {{ $t("create_game") }}
            </b-button>
            <b-button
              variant="success"
              @click="createGame()"
              class="mt-1"
              v-else
            >
              <b-icon-plus /> {{ $t("create_game") }}
            </b-button>
            <b-button class="mx-2 mt-2" @click="showGameList()">
              <b-icon-search /> {{ $t("public_games") }}
            </b-button>
            <b-button class="mx-2 mt-2" @click="showQRScanner()">
              <b-icon-upc-scan /> {{ $t("scan") }}
            </b-button>
          </div>
        </div>
        <div class="notice" v-if="notice">
          <b-icon-exclamation-circle class="notice-icon" />
          <div v-html="notice"></div>
        </div>
        <GameList
          ref="gameList"
          class="mt-5 game-list"
          @join="onJoinPublicGame($event)"
          :class="{ 'game-list-visible': gameListVisible }"
        />
        <!--
        <div class="d-flex playstore" style="justify-content: center">
          <a
            href="https://play.google.com/store/apps/details?id=com.traumsoft.stadtlandfluss"
            class="mb-2"
            target="_blank"
            v-if="!isApp"
          >
            <img
              src="../assets/img/button/playstore.png"
              style="width: 200px"
            />
          </a>
        </div>
      -->
        <DiscordWidget class="mt-2 hcenter" v-if="!isApp" />
      </div>
    </div>
    <QRScanModal ref="qrscan" />
    <FaqList class="mt-1" v-if="!isApp" />
    <ScreenshotGallery v-if="!isApp" />
    <Footer class="mt-2" v-if="!isApp" />
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import GameList from "../components/home/GameList";
import FaqList from "../components/home/FaqList";
import ScreenshotGallery from "../components/home/ScreenshotGallery";
import Footer from "../components/home/Footer";
import DiscordWidget from "../components/home/DiscordWidget";
import QRScanModal from "../components/home/QRScanModal";
import i18n from "../plugins/i18n";
import ProfileImageDisplay from "../components/profile/ProfileImageDisplay";

export default {
  name: "HomePage",
  data: () => {
    return {
      maintenance: false,
      notice: undefined,
      debugCount: 0,
      menuItems: [
        {
          label: "create_game",
          icon: "plus",
          action: "create",
        },
      ],
      gameListVisible: false,
    };
  },
  components: {
    NavBar,
    GameList,
    FaqList,
    ScreenshotGallery,
    Footer,
    DiscordWidget,
    QRScanModal,
    ProfileImageDisplay,
  },
  computed: {
    titleImage() {
      const titleLang = i18n.locale === "de" ? "de" : "en";
      return require(`../assets/img/title/title_${titleLang}.png`);
    },
    isApp() {
      return window.app.mobile;
    },
    isAlpha() {
      return window.app.alpha;
    },
  },
  methods: {
    showLogin() {
      this.$parent.showUserProfile = true;
      this.$gtag.event("click_login", {
        event_category: "Profile",
        event_label: "Login clicked",
        value: 0,
      });
    },
    menuSelect(item) {
      switch (item.action) {
        case "create":
          if (!this.maintenance) {
            this.createGame();
          } else {
            this.openMainenanceFallback();
          }
          break;
      }
    },
    onDebugClick() {
      console.log("Debug click");
      this.debugCount++;
      if (this.debugCount > 10) {
        console.log("Emit");
        this.$emit("toggleDebug");
      }
    },
    openMainenanceFallback() {
      window.open("https://stadtlandfluss.cool");
    },
    hideGameCreator() {
      this.$refs["modal-gamecreator"].hide();
    },
    onJoinPublicGame(game) {
      this.$parent.join(game.server, game.id);
    },
    createGame() {
      this.$parent.auth = "create";
      this.$gtag.event("create_game", {
        event_category: "Game",
        event_label: "Game on home page created",
        value: 0,
      });
    },
    showGameList() {
      if (!this.gameListVisible) {
        this.gameListVisible = true;
        this.$refs.gameList.reload();
      }
    },
    showQRScanner() {
      this.$refs.qrscan.show();
    },
    openAppointa() {
      this.$gtag.event("appointa_ad", {
        event_category: "Ads",
        event_label: "Appointa ad clicked",
        value: 0,
      });
    },
  },
};
</script>
<style scoped>
#splash-screen-content {
  position: relative;
  top: 60px;
  transition: 300ms;
}

#splash-screen {
  min-height: 100vh;
  width: 100vw;
}

.title-image {
  max-width: 95vw;
}

.notice {
  max-width: 90vw;
  width: 400px;
  background-color: white;
  border-radius: 0.5em;
  box-shadow: 0 0 1em black;
  transform: translateX(-50%);
  margin-left: 50%;
  padding: 1em;
  display: flex;
  margin-top: 2em;
}

.notice-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.5em;
  font-size: 2em;
  color: red;
}
#main-title {
  text-align: center;
  width: fit-content;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}
#main-title > h1 {
  font-size: 600%;
  margin-bottom: 0;
}
#main-title > h4 {
  margin-top: 0;
}
#main-title-icons > img {
  width: 100px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  filter: drop-shadow(2px 4px 6px black);
}
#main-title-icons > img:nth-child(2) {
  width: 200px;
}

@media (max-width: 500px) {
  #main-title > h1 {
    font-size: 400%;
  }
  #main-title-icons > img {
    width: 60px;
  }
  #main-title-icons > img:nth-child(2) {
    width: 120px;
  }
}
#main-title-buttons {
  margin-left: 50%;
  width: fit-content;
  transform: translateX(-50%);
}
#main-title-buttons > button {
  border: 2px solid black;
}
.game-list {
  transition: opacity 300ms;
  opacity: 0;
  height: 0;
}
.game-list-visible {
  opacity: 1;
  height: unset;
}
.logo-dummy {
  width: 500px;
  max-width: 95vw;
  height: 250px;
  background-color: lightgray;
  opacity: 0.6;
  border: 2px solid black;
  text-align: center;
  font-size: 5rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 6rem;
}

.advertisement {
  position: absolute;
  width: 250px;
  right: 0;
  top: 50px;
  filter: drop-shadow(0 0 10px gray);
  border-top-left-radius: 1em;
}
@media (max-width: 1100px) {
  .advertisement {
    display: none;
  }
}
</style>
