<template>
  <div>
    <div
      class="message"
      :class="{ 'text-info': colored }"
      v-if="message.type === 'system'"
    >
      <b-icon-info-square /> {{ $t(message.content) }}
    </div>
    <div
      class="message"
      :class="{ 'text-danger': colored }"
      v-if="message.type === 'exit'"
    >
      - {{ message.content }}
    </div>
    <div
      class="message"
      :class="{ 'text-success': colored }"
      v-if="message.type === 'join'"
    >
      <template v-if="message.content"> + {{ message.content }} </template>
      <template v-else> {{ $t("player_joined") }} </template>
    </div>
    <div
      class="message"
      :class="{ 'text-secondary': colored }"
      v-if="message.type === 'chat'"
    >
      <div v-if="colored" style="overflow-wrap: break-word">
        <div class="d-flex">
          <!--<img class="avatar mr-1 my-auto" :src="getAvatarById(message.content.sender)" />-->
          <ProfileImageDisplay :image="image" size="small-icon"/>
          <div>
            <b>{{ getName(message.content.sender) }}:</b>
            <div class="text-muted">{{ message.content.message }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        {{ getName(message.content.sender) }}: {{ message.content.message }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../plugins/i18n";
import ProfileImageDisplay from "../profile/ProfileImageDisplay";

export default {
  name: "ChatMessage",
  props: ["message", "colored", "game"],
  components: {
    ProfileImageDisplay
  },
  computed: {
    image() {
      console.log("User info", this.game.playerList[this.message.content.sender])
      return this.game.playerList[this.message.content.sender].userInfo.image;
    }
  },
  methods: {
    getName(playerId) {
      if (this.game.playerList[playerId]) {
        return this.game.playerList[playerId].name;
      } else {
        console.log(this.game.playerList);
        return i18n.t("unknown_player");
      }
    },
    getAvatarById(playerId) {
      return require("../../assets/avatars/" +
        this.game.playerList[playerId].avatar +
        ".png");
    },
  },
};
</script>

<style scoped>
.page-title > h1 {
  text-align: center;
}
.avatar {
  height: 2em;
}
hr {
  width: 500px;
  max-width: 50vw;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
