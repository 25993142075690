<template>
  <div>
    <h1 class="text-center">{{ $t("how_it_works") }}</h1>
    <hr style="max-width: 400px;">
    <div
      v-for="(text, index) of textEntries"
      :key="text.title"
      class="text-entry"
    >
      <div class="d-flex">
        <img class="icon-image" :src="text.image" />
        <div>
          <h2>{{ index + 1 }}. {{ text.title }}</h2>
          <div>
            {{ text.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../plugins/i18n";
export default {
  name: "FaqList",
  computed: {
    textEntries() {
      return this.texts[i18n.locale];
    },
  },
  data: () => {
    return {
      texts: {
        de: [
          {
            title: "Spiel erstellen",
            image: require("../../assets/img/faq/jigsaw.svg"),
            text: `
                            Drücke einfach auf den grünen "Erstellen" Knopf um ein Spiel zu starten.
                            Das Spiel ist standardmäßig privat, also nur für eingeladene Mitspieler sichtbar.
                            Nachdem du deinen Namen eingegeben hast, kannst du in der Lobby die Spielkategorien auswählen.
                            Außerdem kannst du Einstellungen treffen, wie zum Beispiel die Anzahl der Runden oder die Zeitbegrenzung.
                            Das Spiel existiert so lange, bis der letzte Spieler das Spiel verlässt.
                        `,
          },
          {
            title: "Freunde einladen",
            image: require("../../assets/img/faq/group.svg"),
            text: `
                            Um deine Freunde zu deinem Spiel einzuladen musst du ihnen nur den Link zum Spiel senden.
                            Dazu kannst du den Link aus deinem Browser kopieren, oder den "Kopieren"-Knopf über den Einstellungen benutzen.
                            Die Spielerzahl pro Spiel ist nicht begrenzt.
                            Wenn alle da sind kannst du das Spiel starten.
                        `,
          },
          {
            title: "Beantworte die Begriffe",
            image: require("../../assets/img/faq/brain.svg"),
            text: `
                            Beim Starten der Runde erscheint ein zufälliger Buchstabe. 
                            Alle Spieler haben nun die Möglichkeit Wörter mit diesem Anfangsbuchstaben in die Felder für die gewählten Begriffe einzutragen. 
                            Die Eingabe endet wenn ein Spieler auf "Fertig" drückt oder wenn die eingestellte Zeit abläuft.
                        `,
          },
          {
            title: "Diskuriere die Antworten",
            image: require("../../assets/img/faq/talk.svg"),
            text: `
                            Im folgenden Schritt werden die Antworten aller Spieler angezeigt. 
                            Jeder Spieler hat die Möglichkeit Antworten abzulehnen. Wenn mehr als die Hälfte der Spieler eine Antwort abgelehnt hat erhält der Spieler dafür keine Punkte. 
                            Außerdem können pro Runde eine kleine Anzahl von Kreativpunkten verteilt werden.
                        `,
          },
          {
            title: "Die Ergebnisse",
            image: require("../../assets/img/faq/podium.svg"),
            text: `
                            Nach jeder Runde werden die Zwischenergebnisse angezeigt. 
                            Dort sieht man, welcher Spieler wie viele Punkte hat und wie viele er in der Runde bekommen hat. 
                            Durch den Gastgeber kann die nächste Runde gestartet werden.
                        `,
          },
        ],
        en: [
          {
            title: "Create a game",
            image: require("../../assets/img/faq/jigsaw.svg"),
            text: `
                            Just press the green "Create" button to start a game.
                            The game is private by default, i.e. only visible to invited players.
                            After entering your name, you can select the game categories in the lobby.
                            You can also make settings, such as the number of laps or the time limit.
                            The game will exist until the last player leaves the game.
                        `,
          },
          {
            title: "Invite your friends",
            image: require("../../assets/img/faq/group.svg"),
            text: `
                            To invite your friends to your game you just have to send them the link to the game.
                            To do this, you can copy the link from your browser or use the "Copy" button above the settings.
                            The number of players per game is not limited.
                            When everyone is there you can start the game.
                        `,
          },
          {
            title: "Think & type your answers",
            image: require("../../assets/img/faq/brain.svg"),
            text: `    
                            A random letter appears at the start of the round.
                            All players now have the opportunity to enter words with this first letter in the fields for the selected terms.
                            The input ends when a player presses "Done" or when the set time expires.
                        `,
          },
          {
            title: "Discuss the answers",
            image: require("../../assets/img/faq/talk.svg"),
            text: `
                            In the following step the answers of all players are displayed.
                            Every player has the option to decline answers. If more than half of the players have rejected an answer, the player will not receive any points for it.
                            In addition, a small number of creative points can be distributed per round.
                        `,
          },
          {
            title: "The results",
            image: require("../../assets/img/faq/podium.svg"),
            text: `
                            The intermediate results are displayed after each round.
                            There you can see which player has how many points and how many he got in the round.
                            The next round can be started by the host.
                        `,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.icon-image {
  max-width: 200px;
  width: 20%;
  margin-right: 1em;
}
.text-entry {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 2em;
}
</style>