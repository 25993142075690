<template>
  <div class="score-list-entry">
    <div class="d-flex">
      <div class="profile-img">
        <ProfileImageDisplay :image="image" :player="player" size="icon">
        <div class="place-number">{{ place }}.</div>
        </ProfileImageDisplay>
      </div>
      <div>
        <b>
          {{name}}
        </b>
        <div>
          <b-badge variant="success"
            >{{ points }} {{ $t("points") }}</b-badge
          >
          <b-badge v-if="!final" variant="light">
            + {{ newPoints }}
          </b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImageDisplay from "../profile/ProfileImageDisplay";

export default {
  name: "ScoreListEntry",
  props: ["image", "name", "newPoints", "points", "place", "player", "final"],
  components: {
      ProfileImageDisplay
  }
};
</script>

<style scoped>
.profile-img {
  position: relative;
  margin-right: 1em;
}
.profile-img > img {
  width: 3em;
}
.place-number {
  font-size: 1em;
  background-color: white;
  border-radius: 50%;
  max-width: 1.2rem;
  max-height: 1.2rem;
  color: black;
  text-shadow: 0 0 0.5em black;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.score-list-entry {
  width: 100%;
  height: 3em;
  transition: 500ms;
}
.score-list {
  position: relative;
}
</style>
