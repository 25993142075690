<template>
  <div class="chat">
    <div class="chat-fab bg-info" @click="toggle()">
      <div class="fab-icon">
        <b-icon-x v-if="visible" />
        <b-icon-chat-right-text v-else />
      </div>
      <div
        class="message-hint"
        :class="{ 'hint-visible': hintMessage !== undefined }"
      >
        <ChatMessage
          v-if="hintMessage"
          :colored="false"
          :message="hintMessage"
          :game="game"
        />
      </div>
    </div>
    <div class="chat-display" :class="{ 'chat-display-visible': visible }">
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('chat')" active>
          <div>
            <div class="history">
              <div class="history-content" ref="history">
                <div v-for="(message, index) in game.chat" :key="index">
                  <ChatMessage
                    :colored="true"
                    :message="message"
                    :game="game"
                  />
                </div>
              </div>
            </div>
            <b-input-group v-if="game.settings.chat || game.playerProfile.admin">
              <b-form-input
                type="text"
                maxlength="200"
                :placeholder="$t('message') + '...'"
                v-model="messageInput"
                @keydown="onKeyDown($event)"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="info"
                  @click="sendMessage()"
                  :disabled="messageInput.length < 2"
                  v-b-tooltip.hover
                  :title="$t('send')"
                  ><b-icon-caret-right
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <small v-else class="text-danger">
              {{ $t('chat_disabled') }}
            </small>
          </div>
        </b-tab>
        <b-tab :title="$t('players') + ' (' + playerCount + ')'">
          <PlayerList :game="game" />
        </b-tab>
        <b-tab :title="$t('feedback')">
          <Feedback :game="game"/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ChatMessage from "./ChatMessage";
import PlayerList from "../lobby/PlayerList";
import Feedback from "./Feedback";

export default {
  name: "Chat",
  props: ["game"],
  components: {
    ChatMessage,
    PlayerList,
    Feedback
  },
  data: () => {
    return {
      visible: false,
      hintMessage: undefined,
      hintMessageTask: undefined,
      messageInput: "",
    };
  },
  computed: {
    playerCount() {
      let count = 0;
      for(const key in this.game.playerList) {
        if(this.game.playerList[key].conn === 'on') {
          count++;
        }
      }
      return count;
    }
  },
  mounted() {
    window.addEventListener("chat", (message) => {
      this.onNewMessage(message.detail);
    });
  },
  updated() {
    this.$refs.history.scrollTo(0, this.$refs.history.scrollHeight);
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    onNewMessage(message) {
      if (!this.visible) {
        this.hintMessage = message;
        if (this.hintMessageTask !== undefined) {
          clearTimeout(this.hintMessageTask);
        }
        this.hintMessageTask = setTimeout(() => {
          this.hintMessage = undefined;
          this.hintMessageTask = undefined;
        }, 2000);
      }
    },
    onKeyDown(event) {
      if (event.key === "Enter" && this.messageInput.length >= 2) {
        this.sendMessage();
      }
    },
    sendMessage() {
      this.game.sendChat(this.messageInput);
      this.messageInput = "";
    },
  },
};
</script>

<style scoped>
.chat {
  position: fixed;
  z-index: 99;
}
.chat-fab {
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
  width: 4em;
  height: 4em;
  cursor: pointer;
  transition: 200ms;
  border-radius: 50%;
  box-shadow: 0 0 1em black;
}
.chat-fab:hover {
  box-shadow: 0 0 2em black;
  filter: brightness(1.1);
}
.message-hint {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transform: translate(calc(-100% - 0.2em), 1em);
  clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
  pointer-events: none;
  opacity: 0;
  transition: 300ms;
  max-width: calc(100vw - 6em);
  overflow: hidden;
  top: 1em;
  white-space: nowrap;
  padding: 0.5em;
  padding-right: 1em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  width: fit-content;
}
.hint-visible {
  opacity: 1;
}
.fab-icon {
  color: white;
  font-size: 1.5em;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
}
.chat-display {
  position: fixed;
  transform-origin: bottom right;
  transform: scale(0) rotate(-90deg);
  bottom: 6em;
  right: 1em;
  transition: 300ms;
  background-color: white;
  padding: 1em;
  border-radius: 0.5em;
  width: 400px;
  max-width: calc(100vw - 2em);
  box-shadow: 0 0 3em black;
}
.chat-display-visible {
  transform: scale(1);
}
.history-content {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
