<template>
  <div style="display: flex; justify-content: center">
    <b-card
      title="Neue Anwendung vom Entwickler testen"
      tag="article"
      img-src="https://geoscore.io/assets/images/low_angle_houses.webp"
      img-alt="Image"
      img-top
      style="max-width: 20rem; position: relative;"
      class="mb-2"
    >
      <b-card-text>
        Wie gut ist die Lebensqualität an deinem Standort im Vergleich zu
        anderen? Teste die Anwendung jetzt in der Beta-Version und schau dir den GeoScore für jede Adresse weltweit an.
      </b-card-text>

      <b-button href="https://geoscore.io" target="_blank" variant="primary">GeoScore.io testen</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  props: [],
  data: () => {
    return {};
  },
};
</script>