<template>
  <b-modal ref="modal" :title="$t('change_image')" hide-footer>
      <div v-if="editStage === undefined">
          <p>{{ $t('how_change_image') }}</p>
          <b-btn @click="editStage = 'avatar'" class="mr-2 mt-2" variant="info"><b-icon-person /> {{ $t('create_avatar') }}</b-btn>
          <b-btn @click="editStage = 'upload'" class="mt-2" variant="info"><b-icon-upload /> {{ $t('upload_image') }} </b-btn>
      </div>
      <b-btn variant="light" v-if="editStage !== undefined" @click="editStage = undefined;">
          <b-icon-arrow-left /> {{ $t('back') }}
      </b-btn>
      <div v-if="editStage === 'avatar'">
        <AvatarCustomizer :avatarData="currentImage.avatar" @avatarChanged="avatarEdit=$event" @save="save()"/>
      </div>
      <div v-if="editStage === 'upload'">
          <ImageUploader ref="imageUploader" @save="save()"/>
      </div>
  </b-modal>
</template>

<script>
import AvatarCustomizer from "./avatar/AvatarCustomizer";
import ImageUploader from "./image/ImageUploader";
const Client = require('../../client/client');

export default {
  name: "ChangeImageDialog",
  props: ['currentImage'],
  components: {
      AvatarCustomizer,
      ImageUploader
  },
  data: () => ({
      editStage: undefined,
      avatarEdit: undefined
  }),
  methods: {
      show() {
          this.editStage = undefined;
          this.$refs.modal.show();
      },
      save() {
          switch(this.editStage) {
              case 'avatar':
                  Client.default.editProfile('image', {type: 'avatar', avatar: this.avatarEdit}).then(response => {
                      this.$refs.modal.hide();
                  });
                  break;
              case 'upload':
                  Client.default.editProfile('image', {type: 'image', base64: this.$refs.imageUploader.getImageData()}).then(response => {
                      this.$refs.modal.hide();
                  });
                  break;
          }
      }
  }
};
</script>