<template>
  <div class="page-title mt-2">
    <div class="title-content">
      <h1>
        {{ text }}
      </h1>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["text", "append"],
};
</script>

<style scoped>
.page-title {
  width: fit-content;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  max-width: 95vw;
}
.title-content {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
hr {
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.append {
  font-weight: bold;
  background-color: white;
  padding: 0.5em;
  border-radius: 0.5em;
}
</style>
