<template>
  <CenterInfoPanel>
    <QRModal :content="link" ref="qrmodal"/>
    <b-row class="mx-0">
      <div class="my-auto mr-1">
        <b-icon-link-45deg /> {{ $t("link_title") }}:
      </div>
      <b class="my-auto mr-2 text-info">{{ link }}</b>
      <b-button class="mx-auto" size="sm" variant="success" @click="copyLink()">
        <b-icon-clipboard-plus v-if="!copied" />
        <b-icon-clipboard-check v-else />
        {{ copied ? $t("copied") : $t("copy") }}
      </b-button>
      <b-button class="ml-1" size="sm" variant="success" @click="showQRModal()">
        <b-icon-upc-scan /> Code
      </b-button>
    </b-row>
  </CenterInfoPanel>
</template>

<script>
import CenterInfoPanel from "../generic/panel/CenterInfoPanel";
import QRModal from "./QRModal";
import i18n from "../../plugins/i18n";

export default {
  name: "LinkShare",
  props: ["game"],
  components: {
    CenterInfoPanel,
    QRModal
  },
  data: () => {
    return {
      copied: false,
    };
  },
  computed: {
    link() {
      return (
        window.location.origin + "/#" + this.game.server + "_" + this.game.id
      );
    },
  },
  methods: {
    copyLink() {
      if (
        navigator.clipboard !== undefined &&
        navigator.clipboard.writeText !== undefined
      ) {
        navigator.clipboard.writeText(this.link);
        this.copied = true;
      } else {
        this.$alert(i18n.t('copy_url_fail'), i18n.t('error'), 'error');
      }
    },
    showQRModal() {
      this.$refs.qrmodal.show();
    }
  },
};
</script>