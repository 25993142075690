<template>
  <img :src="srcPath"/>
</template>

<script>
export default {
  name: "CustomSvgIcon",
  props: ["icon"],
  computed: {
      srcPath() {
          return require('../../assets/icons/' + this.icon + '.svg');
      }
  }
};
</script>

<style scoped>
img {
    height: 1.25em;
}
</style>
