<template>
  <b-modal ref="modal" :title="$t('contact')">
    <p>{{ $t("contact_hint") }}</p>
    <b-form-textarea v-model="msg" :placeholder="$t('contact_message')" />
    <b-form-checkbox v-model="answer">
      {{ $t("contact_answer") }}
    </b-form-checkbox>
    <b-form-input
      v-model="mail"
      v-if="answer"
      :placeholder="$t('contact_mail')"
    ></b-form-input>
    <template slot="modal-footer">
      <b-btn variant="success" @click="send()" :disabled="!valid || sending">
        <b-icon-check /> {{ $t("send") }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import Client from "../../client/client";

export default {
  name: "ContactDialog",
  data: () => ({
    msg: "",
    answer: false,
    mail: "",
    sending: false
  }),
  computed: {
    valid() {
      return (
        this.msg.length >= 10 &&
        (!this.answer || (this.mail.length >= 5 && this.mail.includes("@")))
      );
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
        this.$refs.modal.hide();
    },
    send() {
        this.sending = true;
        Client.sendContact(this.msg, this.answer, this.mail).then((response) => {
            this.sending = false;
            if(response.success) {
                this.hide();
                this.msg = '';
                this.$alert(this.$i18n.t('contact_sent'), this.$i18n.t('contact'), 'success');
            } else {
                this.$alert('There was a problem sending your message. Feel free to directly contact david@traum.me', 'Error', 'error');
            }
        });
    }
  },
};
</script>