<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  name: "PointChartDisplay",
  props: ["profileData"],
  data: () => ({
    monthNames: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
  }),
  mounted() {
    this.render();
  },
  methods: {
    formatTime(time) {
      let date = new Date(time * 60000);
      return date.getDate() + ". " + this.monthNames[date.getMonth()];
    },
    render() {
      console.log("Render data", this.profileData);
      const labels = [];
      for (let game of this.profileData.games) {
        labels.push(this.formatTime(game.time));
      }
      const data = [];
      for (let game of this.profileData.games) {
        data.push(game.points);
      }
      this.renderChart(
        {
          labels: labels,
          datasets: [
            {
              label: "Punkte",
              data: data,
              fill: false,
              borderColor: '#17a2b8'
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }
      );
    },
  },
};
</script>