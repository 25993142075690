<template>
  <div class="row mx-2">
    <div v-for="(image, index) of images" :key="index" class="entry mx-auto col mb-2">
      <img class="screenshot" :src="image" />
      <div class="label">
        {{ labels[index] }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../plugins/i18n";

export default {
  computed: {
    labels() {
      return this.labelData[i18n.locale];
    },
  },
  data: () => {
    return {
      images: [
        require("../../assets/img/screenshots/lobby.webp"),
        require("../../assets/img/screenshots/picking_letter.webp"),
        require("../../assets/img/screenshots/game.webp"),
        require("../../assets/img/screenshots/answers.webp"),
      ],
      labelData: {
        de: [
          "Die Lobby",
          "Zufällige Auswahl des Buchstaben",
          "Antworten eingeben",
          "Die Abstimmung & Diskussion",
        ],
        en: [
          "The lobby",
          "Randomly picking a letter",
          "Answering the categories",
          "Voting & discussion",
        ],
      },
    };
  },
};
</script>

<style scoped>
.screenshot {
    max-width: 300px;
}
.label {
    margin-left: auto;
    margin-right: auto;
}
</style>