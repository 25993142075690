<template>
  <CollapsePanel
    ref="panel"
    :header="$t('settings')"
    @collapse="$emit('collapse')"
    @open="$emit('open')"
  >
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('important_settings')" active>
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          :label="$t('rounds') + ': ' + game.settings.rounds"
          label-for="input-rounds"
        >
          <b-form-input
            id="input-rounds"
            type="range"
            variant="info"
            min="1"
            :disabled="!isAdmin"
            max="15"
            step="1"
            :value="game.settings.rounds"
            @input="onRoundsInput($event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          :label="$t('round_end')"
          label-for="input-stop-mode"
        >
          <b-form-select
            id="input-stop-mode"
            :disabled="!isAdmin"
            :value="game.settings.roundStop"
            @input="onRoundStopInput($event)"
          >
            <b-form-select-option value="stop">{{
              $t("end_stop")
            }}</b-form-select-option>
            <b-form-select-option value="timer">{{
              $t("end_timer")
            }}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          :label="
            $t('time_limit') +
            ': ' +
            game.settings.timeLimit +
            ' ' +
            $t('minutes')
          "
          label-for="input-time-limit"
        >
          <b-form-input
            id="input-time-limit"
            type="range"
            variant="info"
            min="1"
            :disabled="!isAdmin"
            max="15"
            step="1"
            :value="game.settings.timeLimit"
            @input="onTimeLimitInput($event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          :label="$t('score_mode')"
          label-for="input-stop-mode"
        >
          <b-form-select
            id="input-stop-mode"
            :disabled="!isAdmin"
            :value="game.settings.scoreMode"
            @input="onScoreModeInput($event)"
          >
            <b-form-select-option value="live">{{
              $t("score_live")
            }}</b-form-select-option>
            <b-form-select-option value="round">{{
              $t("score_round")
            }}</b-form-select-option>
            <b-form-select-option value="end">{{
              $t("score_end")
            }}</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <ToggleSwitch
          :label="$t('public_game')"
          :state="game.settings.public"
          :disabled="!isAdmin"
          @toggle="onSetGamePublic($event)"
        />
      </b-tab>
      <b-tab :title="$t('expert_settings')">
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          :description="$t('min_done_players_explain')"
          :label="$t('min_done_players') + ': ' + game.settings.stopCount"
          label-for="input-min-done-players"
        >
          <b-form-input
            id="input-min-done-players"
            type="range"
            variant="info"
            min="1"
            :disabled="!isAdmin"
            max="5"
            step="1"
            :value="game.settings.stopCount"
            @input="onStopCountInput($event)"
          ></b-form-input>
        </b-form-group>
        <ToggleSwitch
          :label="$t('allow_chat')"
          :state="game.settings.chat"
          :disabled="!isAdmin"
          @toggle="onSetChat($event)"
        />
        <ToggleSwitch
          :label="$t('anonymus_vote')"
          :state="game.settings.anonymus"
          :disabled="!isAdmin"
          @toggle="onSetAnonymus($event)"
        />
        <ToggleSwitch
          label="Geplantes Spiel"
          :state="game.settings.planned"
          :disabled="!isAdmin"
          @toggle="onSetPlanned($event)"
        />
        <b-form-group
          id="fieldset-horizontal"
          v-if="game.settings.planned"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Die Anzahl der Tage nach denen das Spiel aufgelöst wird."
          :label="`Laufzeit: ${game.settings.plannedDays} Tage`"
          label-for="input-planned-time"
        >
          <b-form-input
            id="input-planned-time"
            type="range"
            variant="info"
            min="1"
            :disabled="!isAdmin"
            max="14"
            step="1"
            :value="game.settings.plannedDays"
            @input="onInputPlannedDays($event)"
          ></b-form-input>
        </b-form-group>
      </b-tab>
      <b-tab title="Teams">
        <TeamSettings :game="game" />
      </b-tab>
    </b-tabs>
  </CollapsePanel>
</template>

<script>
import CollapsePanel from "../generic/panel/CollapsePanel";
import ToggleSwitch from "../generic/ToggleSwitch";
import TeamSettings from "./TeamSettings";

export default {
  name: "AdvancedSettings",
  props: ["game"],
  components: {
    CollapsePanel,
    ToggleSwitch,
    TeamSettings,
  },
  computed: {
    isAdmin() {
      return this.game.playerProfile.admin;
    },
    roundStopOptions() {
      return {};
    },
  },
  methods: {
    collapse() {
      this.$refs.panel.collapse();
    },
    onRoundsInput(value) {
      this.game.setSetting("rounds", value);
    },
    onRoundStopInput(value) {
      this.game.setSetting("roundStop", value);
    },
    onTimeLimitInput(value) {
      this.game.setSetting("timeLimit", value);
    },
    onSetGamePublic(value) {
      this.game.setSetting("public", value);
    },
    onStopCountInput(value) {
      this.game.setSetting("stopCount", value);
    },
    onSetChat(value) {
      this.game.setSetting("chat", value);
    },
    onSetAnonymus(value) {
      this.game.setSetting("anonymus", value);
    },
    onScoreModeInput(value) {
      this.game.setSetting("scoreMode", value);
    },
    onSetPlanned(value) {
      this.game.setSetting("planned", value);
    },
    onInputPlannedDays(value) {
      this.game.setSetting("plannedDays", value);
    },
  },
};
</script>

<style scoped>
</style>
