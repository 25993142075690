<template>
  <div class="bg bg-0">
    <NavBar
      :title="$t('title')"
      :items="menuItems"
      @select="menuSelect($event)"
    />
    <PingView :ping="game.ping" />
    <PageTitle
      :text="
        isFinal
          ? $t('final_result')
          : $t('results') + ' ' + $t('round') + ' ' + game.round.number
      "
    />

    <div class="d-flex">
      <div class="score-display mx-auto">
        <VoteScore :game="game" :final="isFinal" ref="voteScore">
          <hr />
          <!--
          <b-button
            variant="info"
            style="float: left"
            @click="$refs.voteScore.showExport()"
          >
            <b-icon-image /> Exportieren
          </b-button>
          -->
          <b-button
            variant="success"
            style="float: right"
            v-if="game.playerProfile.admin"
            @click="finish()"
          >
            <b-icon-arrow-right />
            {{ isFinal ? $t("to_lobby") : $t("next_round") }}
          </b-button>
          <b-badge variant="info" style="float: right" v-else>
            <b-icon-clock /> {{ $t("waiting_for_admin") }}
          </b-badge>
        </VoteScore>
      </div>
    </div>
    <div class="d-flex mt-2" v-if="isFinal" style="width: 100%;">
        <other-app-advertisement style="width: 100%;" />
      </div>
    <Chat :game="game" />
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import PingView from "../components/generic/PingView";
import PageTitle from "../components/generic/PageTitle";
import Chat from "../components/generic/Chat";
import VoteScore from "../components/vote/VoteScore";
import OtherAppAdvertisement from "../components/home/OtherAppAdvertisement.vue";

export default {
  name: "ResultsPage",
  props: ["game"],
  components: {
    NavBar,
    PingView,
    PageTitle,
    Chat,
    VoteScore,
    OtherAppAdvertisement
  },
  data: () => {
    return {
      
    OtherAppAdvertisementmenuItems: [
        {
          label: "exit_game",
          icon: "box-arrow-left",
          action: "exit",
        },
      ],
    };
  },
  mounted() {
    if (this.game.round.number >= this.game.settings.rounds) {
      console.log("Game finished.");
    }
  },
  computed: {
    isFinal() {
      return (
        this.game.round && this.game.round.number >= this.game.settings.rounds
      );
    },
  },
  methods: {
    menuSelect(item) {
      switch (item.action) {
        case "exit":
          this.exit();
          break;
      }
    },
    exit() {
      this.$parent.exitGame();
    },
    finish() {
      this.game.finishResults();
    },
  },
};
</script>
<style scoped>
.score-display {
  width: 400px;
  max-width: 95vw;
}
</style>
