<template>
  <div>
    <ForgotPasswordDialog ref="forgotPasswordDialog" />
    <p class="mb-0 mt-2">{{$t('username_or_mail')}}:</p>
    <b-form-input
      class="mt-0"
      v-model="input.username"
      :placeholder="$t('your_username')"
      @keydown="onInputKeydown($event)"
      trim
    ></b-form-input>

    <p class="mb-0 mt-2">{{$t('password')}}:</p>
    <b-form-input
      class="mt-0"
      v-model="input.password"
      type="password"
      :placeholder="$t('your_password')"
      @keydown="onInputKeydown($event)"
      trim
    ></b-form-input>

    <b-badge v-if="error" variant="danger">{{error[1]}} ({{error[0]}})</b-badge>
    <div style="justify-content: space-between; flex-wrap: wrap;" class="d-flex mt-2">
        <p class="text-info" style="cursor: pointer;" @click="$refs.forgotPasswordDialog.show()">{{$t('forgot_password')}}?</p>
        <b-btn variant="success" :disabled="!isValid" @click="submit()"> <b-icon-check /> {{$t('login')}} </b-btn>
    </div>
  </div>
</template>

<script>
import Client from "../../client/client";
import ForgotPasswordDialog from "./ForgotPasswordDialog.vue";

export default {
  name: "LoginForm",
  components: {
    ForgotPasswordDialog
  },
  data: () => ({
    input: {
      username: "",
      password: "",
    },
    error: undefined
  }),
  methods: {
      submit() {
          Client.createSession(this.input.username, this.input.password).then(response => {
            if(response.success) {
              this.$alert('Du hast dich erfolgreich angemeldet.', 'Erfolg', 'success');
              Client.session = response.session;
            } else {
              this.error = response.error;
            }
          });
      },
      onInputKeydown(event) {
        if(event.key === 'Enter' && this.isValid) {
          this.submit();
        }
      }
  },
  computed: {
      usernameValid() {
          return this.input.username.length > 2 && this.input.username.length <= 30;
      },
      passwordValid() {
          return this.input.password.length >= 6 && this.input.password.length <= 50;
      },
      isValid() {
          return this.usernameValid && this.passwordValid;
      }
  }
};
</script>