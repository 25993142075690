<script>
import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  name: "RankChartDisplay",
  props: ["profileData"],
  data: () => ({
    background: [
      '#f7d100',
      '#bbbbbb',
      '#c79664',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray',
      'lightgray'
    ]
  }),
  mounted() {
    this.render();
  },
  methods: {
    render() {
      console.log("prddtd", this.profileData)
      const numbers = [];
      for(let game of this.profileData.games) {
        numbers.push(game.place);
      }
      const labels = [];
            const data = [];
      for(let i = 0; i<1000; i++) {
        if(numbers.includes(i)) {
          labels.push(i);
          data.push(0);
        }
      }
      for (let game of this.profileData.games) {
        data[game.place - 1] += 1;
      }
      this.renderChart(
        {
          labels: labels,
          datasets: [
            {
              label: "Platz",
              data: data,
              backgroundColor: this.background
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }
      );
    },
  },
};
</script>