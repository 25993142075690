<template>
  <div :class="{ 'outer-selected': selected }" class="outer">
    <b-list-group-item
      class="d-flex justify-content-between align-items-center main-item py-0 px-2"
      :style="{ background: backgroundColor }"
      @click="toggleSelect()"
      :class="{
        'main-item-selected': selected,
        'main-item-clickable': game.playerProfile.admin,
      }"
    >
      <b-row class="mx-0">
        <!--
        <img
          :src="profileImageUrl"
          class="profile-img"
          :id="game.playerProfile.id === player.id ? 'player-profile-image' : undefined"
          :tabindex="game.playerProfile.id === player.id ? 1 : undefined"
          :class="{ 'profile-img-selected': selected }"
        />
        -->
        <ProfileImageDisplay
          :image="player.userInfo.image"
          size="icon"
          class="my-1"
          :hoverable="true"
          :player="player"
        />
        <b-btn variant="icon" v-if="!loggedIn && player.id === game.playerId" v-b-tooltip.hover title="Avatar ändern" @click="$emit('editAvatar', player.userInfo.image.avatar)">
          <b-icon-pencil />
        </b-btn>
        <div
          class="my-auto ml-2"
          :class="{
            'text-muted': player.conn !== 'on',
            'label-selected': selected,
          }"
        >
          {{ getPlayerName(player) }}
        </div>
      </b-row>
      <div class="d-flex">
        <LevelBadge
          v-if="player.userInfo.points"
          :points="player.userInfo.points"
          :badge="true"
          class="mr-1"
        />
        <b-badge
          v-if="player.admin"
          variant="success"
          v-b-tooltip.hover
          :title="$t('host')"
          classs="mx-1"
          pill
          ><b-icon-award
        /></b-badge>
        <b-badge
          v-if="player.id === game.playerId"
          variant="info"
          class="mx-1"
          pill
          >{{ $t("you") }}</b-badge
        >
        <b-badge
          v-if="player.conn === 'off'"
          variant="secondary"
          class="mx-1"
          v-b-tooltip.hover
          :title="$t('offline')"
          pill
        >
          <b-icon-x />
        </b-badge>
        <b-badge
          v-if="player.conn === 'kick'"
          variant="danger"
          class="mx-1"
          v-b-tooltip.hover
          :title="$t('kicked')"
          pill
        >
          <b-icon-x />
        </b-badge>
        <b-button
          variant="icon"
          class="py-0"
          v-if="
            game.playerProfile.admin &&
            player.id !== game.playerId &&
            player.conn === 'on'
          "
        >
          <b-icon-three-dots-vertical />
        </b-button>
      </div>
    </b-list-group-item>
    <b-list-group-item
      class="d-flex justify-content-between align-items-center actions"
      v-if="selected"
    >
      <b-row>
        <b-button
          variant="danger"
          size="sm"
          class="mr-1"
          :disabled="player.admin || player.conn !== 'on'"
          @click="kick()"
        >
          <b-icon-x /> {{ $t("kick") }}
        </b-button>
        <b-button
          variant="info"
          size="sm"
          class="mr-1"
          :disabled="player.admin || player.conn !== 'on'"
          @click="makeAdmin()"
        >
          <b-icon-award /> {{ $t("make_admin") }}
        </b-button>
        <TeamSelector
          v-if="game.settings.teamsEnabled"
          :game="game"
          size="sm"
          @select="selectTeam($event)"
        />
      </b-row>
    </b-list-group-item>
    <ProfileImageSelector
      target="player-profile-image"
      :game="game"
      @select="onSelectAvatar($event)"
    />
  </div>
</template>

<script>
import i18n from "../../plugins/i18n";
import ProfileImageSelector from "./ProfileImageSelector";
import ProfileImageDisplay from "../profile/ProfileImageDisplay";
import Teams from "../../../../shared/teams";
import TeamSelector from "./TeamSelector";
import LevelBadge from "../stats/LevelBadge";
import Client from "../../client/client";

export default {
  name: "PlayerListEntry",
  props: ["game", "player", "selected"],
  components: {
    ProfileImageSelector,
    ProfileImageDisplay,
    TeamSelector,
    LevelBadge,
  },
  computed: {
    backgroundColor() {
      if (this.game.settings.teamsEnabled) {
        return Teams.colors[this.player.team];
      } else {
        return "";
      }
    },
    loggedIn() {
      return Client.session !== undefined; 
    }
  },
  methods: {
    getPlayerName(player) {
      if (player.name !== undefined) {
        return player.name;
      } else {
        return i18n.t("player") + " " + player.id;
      }
    },
    toggleSelect() {
      if (this.game.playerProfile.admin) {
        if (!this.selected) {
          this.$emit("select");
        } else {
          this.$emit("deselect");
        }
      }
    },
    onSelectAvatar(avatarId) {
      this.game.setAvatar(avatarId);
    },
    kick() {
      if (this.game.playerProfile.admin) {
        this.game.kickPlayer(this.player.id);
      }
    },
    makeAdmin() {
      if (this.game.playerProfile.admin) {
        this.game.makeAdmin(this.player.id);
      }
    },
    selectTeam(index) {
      this.game.selectTeam(this.player.id, index);
    },
  },
};
</script>

<style scoped>
.outer {
  transition: 200ms;
}
.outer-selected {
  padding: 0.5em;
  background-color: white;
}
.main-item {
  transition: 200ms;
}
.main-item-clickable {
  cursor: pointer;
}
.main-item:hover {
  background-color: rgb(240, 240, 240);
}
.main-item-selected {
  outline: 2px dashed black;
  background-color: rgb(240, 240, 240);
}
.actions {
  border-radius: 0;
  outline: 2px dashed black;
}
.profile-img-selected {
  filter: drop-shadow(0 0 2px black);
}
.profile-img {
  height: 2.5em;
  width: 2.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
</style>