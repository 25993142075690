<template>
  <div class="dropdown">
    <div class="backdrop" @click="hide()" v-if="visible"></div>
    <div
      class="dropdown-dialog"
      :style="{ top: pos.y + 'px', left: pos.x + 'px' }"
      :class="{ left }"
    >
      <div class="content" :class="{ 'content-visible': visible, 'content-left': left}">
        <b-list-group>
          <b-list-group-item v-if="heading">
            {{ heading }}
          </b-list-group-item>
          <slot class="text-secondary" />
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownDialog",
  props: ["heading"],
  data: () => {
    return {
      pos: { x: -1, y: -1 },
      visible: false,
      left: true,
    };
  },
  methods: {
    show(x, y) {
      this.left = x > window.innerWidth - 200;
      this.pos = { x, y };
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 909;
}
.dropdown-dialog {
  position: fixed;
  z-index: 1000;
}
.left {
  transform: translateX(-100%);
}
.content {
  transform: scale(0);
  transition: transform 300ms;
  transform-origin: top left;
}
.content-left {
  transform-origin: top right !important;
}
.content-visible {
  transform: scale(1);
}
</style>
