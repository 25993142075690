<template>
  <div>
    <ToggleSwitch
      label="Teams aktivieren"
      :state="game.settings.teamsEnabled"
      @toggle="onSetEnabled($event)"
      :disabled="!isAdmin"
    />
    <div v-if="game.settings.teamsEnabled">
      <ToggleSwitch
        label="Spieler können ihr Team wählen"
        :state="game.settings.teamsUserSelect"
        @toggle="onSetUserSelect($event)"
        :disabled="!isAdmin"
      />
      <b-btn
        size="sm"
        variant="info"
        @click="throwDice()"
        :disabled="diceRolling"
        v-if="isAdmin"
      >
        <b-icon-dice-6 /> Teams neu würfeln
      </b-btn>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="(team, index) of game.settings.teams"
          :key="index"
          :style="{ background: getColor(index) }"
        >
          <div class="d-flex" style="float: left">
            <div v-if="editNameIndex !== index" class="my-auto">
              {{ team }}
            </div>
            <b-input-group v-else>
              <template #append>
                <b-btn
                  variant="success"
                  size="sm"
                  :disabled="!editTitleValid"
                  @click="saveEditTitle()"
                >
                  <b-icon-check />
                </b-btn>
              </template>
              <b-form-input
                v-model="editName"
                placeholder="Name des Teams..."
                style="max-width: 150px"
                maxlength="30"
                @keydown="onEditTitleKeydown($event)"
              ></b-form-input>
            </b-input-group>

            <b-btn
              variant="icon"
              size="sm"
              @click="editTitle(index)"
              v-if="editNameIndex !== index && isAdmin"
            >
              <b-icon-pencil />
            </b-btn>
          </div>
          <b-btn
            variant="icon"
            size="sm"
            style="float: right"
            @click="removeTeam(index)"
            v-if="game.settings.teams.length > 2 && isAdmin"
          >
            <b-icon-trash />
          </b-btn>
        </b-list-group-item>
      </b-list-group>
      <b-row>
        <b-btn
          class="mx-auto mt-2"
          variant="success"
          pill
          v-b-tooltip.hover
          title="Team hinzufügen"
          @click="addTeam()"
          v-if="game.settings.teams.length < 4 && isAdmin"
          ><b-icon-plus
        /></b-btn>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from "../generic/ToggleSwitch";
import Teams from "../../../../shared/teams";

export default {
  name: "TeamSettings",
  props: ["game"],
  components: {
    ToggleSwitch,
  },
  data: () => ({
    editNameIndex: -1,
    editName: "",
    editNameIntital: "",
    diceRolling: false,
  }),
  computed: {
    editTitleValid() {
      return this.editName.length >= 2 && this.editName.length <= 30;
    },
    isAdmin() {
      return this.game.playerProfile.admin;
    },
  },
  methods: {
    onSetEnabled(state) {
      this.game.setSetting("teamsEnabled", state);
    },
    onSetUserSelect(state) {
      this.game.setSetting("teamsUserSelect", state);
    },
    getColor(index) {
      return Teams.colors[index];
    },
    addTeam() {
      const title = `Team ${this.game.settings.teams.length + 1}`;
      this.game.settings.teams.push(title);
      this.game.addTeam(title);
    },
    removeTeam(index) {
      this.game.settings.teams.splice(index, 1);
      this.game.removeTeam(index);
    },
    editTitle(index) {
      this.editNameIndex = index;
      this.editName = this.game.settings.teams[index];
      this.editNameIntital = this.editName;
    },
    saveEditTitle() {
      if (this.editName !== this.editNameInital) {
        this.game.settings.teams[this.editNameIndex] = this.editName;
        this.game.setSetting("teams", this.game.settings.teams);
      }
      this.editNameIndex = -1;
    },
    onEditTitleKeydown(event) {
      if (event.key === "Enter" && this.editTitleValid) {
        this.saveEditTitle();
      }
    },
    throwDice() {
      this.diceRolling = true;
      this.game.assignRandomTeams();
      setTimeout(() => {
        this.diceRolling = false;
      }, 2000);
    },
  },
};
</script>