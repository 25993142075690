<template>
  <div class="bg picking-letter">
    <h1 class="text-display" v-if="showLetter === undefined">
      {{ $t("game_starts_soon") }}
    </h1>
    <div class="letter-display" :class="{ final }" v-else>{{ showLetter }}</div>
    <div class="profile-list-wrapper">
      <div class="profile-list">
        <b-row class="mx-auto">
          <ProfileImageDisplay v-for="player of playerList" :key="player.name" :player="player" :image="player.userInfo.image" size="large-icon"/>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImageDisplay from "../components/profile/ProfileImageDisplay";

export default {
  name: "PickingLetterPage",
  props: ["game"],
  components: {
    ProfileImageDisplay
  },
  data: () => {
    return {
      showLetter: undefined,
      shuffleTask: undefined,
      letters: "ABCDEFGHIJKLMNOPRSTUVWZ",
      currentLetter: 0,
      final: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.beginShuffle();
    }, 1500);
  },
  computed: {
    playerList() {
      return Object.values(this.game.playerList).filter(player => player.conn === 'on');
    }
  },
  methods: {
    beginShuffle() {
      this.shuffleTask = setInterval(() => {
        this.showLetter = this.letters.charAt(this.currentLetter);
        this.currentLetter++;
        if (this.currentLetter >= this.letters.length) {
          this.currentLetter = 0;
        }
      }, 50);
    },
    setLetter(letter) {
      clearInterval(this.shuffleTask);
      this.showLetter = letter;
      this.final = true;
    },
    getAvatarImage(player) {
      return require("../assets/avatars/" + player.avatar + ".png");
    },
  },
};
</script>
<style scoped>
.picking-letter {
  background-color: rgb(40, 40, 40);
  overflow: hidden;
}
.profile-image {
  width: 5em;
  height: 5em;
}
.profile-list-wrapper {
    position: absolute;
    bottom: 1em;
    width: 100%;
    z-index: 1;
}
.profile-list {
  max-width: 400px;
  margin: auto;
}
.text-display {
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50vh;
  transform: translateY(-50%);
}
.letter-display {
  color: white;
  font-size: 5rem;
  text-align: center;
  width: fit-content;
  z-index: 2;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transition: 1s;
  transform-origin: center;
  transform: translate(-50%, -50%);
}
.final {
  transform: scale(2) translate(-50%, -50%);
  filter: drop-shadow(0 0 3rem rgb(200, 200, 200));
}
</style>
