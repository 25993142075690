import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'en': {
        title: 'categories.cool',
        title_long: 'StadtLandFluss | Online Mehrspieler',
        locale_en: 'English',
        locale_de: 'German',
        create_game: 'Create game',
        multiplayer: 'Multiplayer',
        join_game: 'Join game',
        public_games: 'Public games',
        select_categories: 'Categories',
        more_suggestions: 'More suggestions',
        own_category: 'Own category',
        add_own_category: 'Add own category',
        add: 'Add',
        suggestions: 'Suggestions',
        rounds: 'Rounds',
        round_end: 'Round end',
        round_end_stop: 'Press stop (with timer)',
        round_end_timer: 'Only timer',
        yes: 'Yes',
        no: 'No',
        public: 'Public',
        time_limit: 'Time limit',
        minutes: 'minutes',
        settings: 'Settings',
        abort: 'Abort',
        no_words_selected: 'Please select at least one category',
        exit_game: 'Exit game',
        connecting: 'Connecting to the game...',
        abort: 'Abort',
        lobby: 'Lobby',
        profile: 'Profile',
        players: 'Players',
        settings: 'Settings',
        player: 'Player',
        you: 'You',
        host: 'Admin',
        chat: 'Chat',
        you_are_now_admin: 'You are now an admin',
        player_joined: 'New player joined',
        input_name: 'Enter your name',
        join: 'Join',
        message: 'Message',
        send: 'Send',
        unknown_player: 'Unknown player',
        kick: 'Kick',
        make_admin: 'Make admin (too)',
        offline: 'Offline',
        kicked: 'Kicked by an admin',
        to_home_page: 'Back to home',
        error: 'Error',
        game_not_found: 'This game does not exist anymore.',
        invalid_data: 'Invalid data sent.',
        selected: 'Selected',
        deselect: 'Deselect',
        players_can_add_words: 'Players can edit categories',
        add: 'Add',
        close: 'Close',
        advanced_settings: 'More settings',
        game: 'Game',
        end_stop: 'Press stop (with time limit)',
        end_timer: 'Time limit',
        admin_only: 'Can only be changed by an admin',
        round_end: 'Round end',
        minutes: 'minutes',
        public_game: 'Public game',
        important_settings: 'Basic',
        expert_settings: 'Advanced',
        game_now_public: 'The game is now listed public.',
        game_now_private: 'The game is no longer public.',
        min_done_players: 'Minimum players done',
        min_done_players_explain: 'How much players have to press stop to end the round.',
        allow_chat: 'Allow chat',
        chat_disabled: 'Chatting disabled',
        anonymus_vote: 'Anonymus voting',
        link_title: 'Link to join',
        copy: 'Copy',
        copied: 'Copied',
        start_game: 'Start game',
        start_game_no_players: 'There are no other players yet :( Are you sure that you want to start the game alone? It will be a lot more boring.',
        no_categories: 'Please select at least one category.',
        continue: 'Continue',
        game_starts_soon: 'Get ready...',
        round: 'Round',
        of_rounds: 'of',
        game_input: 'Input',
        player_progress: 'Progress of the other players',
        word_with: 'starts with',
        done: 'Done',
        remaining_time: 'Remaining time',
        thinking_about: 'Thinking about',
        thinking: 'Thinking',
        letter: 'Letter',
        are_you_sure: 'Are you sure?',
        not_all_words: 'You did not answer all categories yet.',
        answers: 'Answers',
        voting: 'Voting',
        score: 'Scores',
        time_is_up: 'The time is up.',
        round_ended: 'Round ended',
        player_is_done: 'is done.',
        reject: 'Ablehnen',
        like: 'Creative bonus',
        points: 'points',
        change_letter: 'Generate new letter',
        no_answers: 'No answers',
        left: 'left',
        likes_left: 'Your likes available in this round',
        search_wiki: 'Search the internet',
        force_done: 'Force done',
        force_done_desc: 'Ends the voting - regardless if all players are done.',
        marked_done: 'Marked as done',
        no_public_games: 'No public games',
        in_game: 'In game',
        in_lobby: 'In the lobby',
        score_mode: 'How to show scores',
        score_live: 'Live during voting',
        score_round: 'After each round',
        score_end: 'When the game ends',
        results: 'Results',
        final_result: 'Final result',
        to_lobby: 'Finish',
        next_round: 'Next round',
        waiting_for_admin: 'Waiting for admin...',
        how_it_works: 'How it works',
        imprint: 'Imprint',
        support_this_page: 'Support this page',
        connection_closed: 'Connection closed',
        kicked_message: 'You have been kicked from the game by an admin.',
        closed_message: 'The connection to the server has been closed.',
        server_offline: 'The server is currently offline. We are working on it.',
        players: 'Players',
        games: 'Games',
        feedback: 'Feedback',
        feedback_text: 'Do you have ideas, problems or criticism towards this game? Send us a message!',
        want_answer: 'I want an answer',
        mail_address: 'Your E-Mail',
        send: 'Send',
        thanks_feedback: 'Many thanks for your feedback! We are constantly trying to improve the game and you are helping a lot!',
        maintenance_message: 'Servers are currently in maintenance. No games can be started right now.',
        profile_image: 'Profile image',
        copy_url_fail: 'The url can not be copied by your browser. Please select the url manually.',
        online: 'online',
        loading: 'Loading',
        reconnecting: 'Connection lost. Reconnecting...',
        qr_help: 'Scan the code with a smartphone app or by using the "Scan Code" button on the landing page to join the game.',
        scan: 'Scan',
        qr_scan_help: 'Scan a valid code to join the game.',
        edit_avatar: 'Edit avatar',
        avatar_login_hint: 'Tipp: with a categories.cooll account you can save your avatar or upload an own image.',
        reset_password: 'Reset password',
        password_mail: 'Enter your e-mail adress to reset your password.',
        reset_success: 'We have sent you an e-mail with further instructions.',
        reset_already_requested: 'You can only request a password reset once every 10 minutes.',
        why_account: 'Why use an account?',
        change_password: 'Change password',
        enter_new_password: 'Enter your new password here.',
        new_password: 'New password',
        repeat_password: 'Repeat password',
        save: 'Save',
        password_changed: 'Password updated.',
        logout: 'Logout',
        homepage: 'Home',
        change_image: 'Change profile image',
        loading_image: 'Loading image...',
        load_image_error: 'Could not load image. Please use a valid image file (.jpg, .png,...)',
        how_change_image: 'How do you want to set your image?',
        create_avatar: 'Create avatar',
        upload_image: 'Upload image',
        back: 'Back',
        avatar_touch_edit: 'Tap the avatar to change the look.',
        avatar_mouse_edit: 'Use the mouse to change the look.',
        accessoriesType: "Accessoires",
        clotheType: "Clothes",
        clotheColor: "Clothe color",
        eyebrowType: "Eyebrows",
        eyeType: "Eyes",
        facialHairColor: "Beard color",
        facialHairType: "Beard",
        graphicType: "Graphic",
        hairColor: "Hair color",
        mouthType: "Mouth",
        skinColor: "Skin",
        topType: "Top type",
        topColor: "Top color",
        random: "Random",
        game_history: "Game history",
        game_history_label: "Here you can see the results of your last games.",
        history_empty: "The is no data yet. If you finish a game the data will appear here.",
        points_per_game: "Points per game",
        ranks: 'Ranks',
        level_progress: 'Level progress',
        total: 'Total',
        points_collected: 'points collected.',
        points: 'Points',
        delete_account: 'Delete account',
        delete_confirm_password: 'Please enter your password to delete your account.',
        account_deleted: 'Account deleted.',
        invalid_password: 'Invalid password',
        privacy: 'Datenschutz',
        contact: 'Contact',
        contact_hint: 'Do you have a problem, feedback or just a question? Feel free to leave us a message!',
        contact_message: 'Your message...',
        contact_answer: 'I would be happy to receive an answer',
        contact_mail: 'Your e-mail address...',
        contact_sent: 'Your message has been sent.',
        login: 'Login',
        username: 'Username',
        your_username: 'Your username...',
        mail_address: 'E-Mail address',
        your_mail_address: 'Your e-mail address...',
        password: 'Password',
        your_password: 'Your password...',
        password_characters: 'Minimum 6 characters',
        repeat_password: 'Repeat password',
        repeat_your_password: 'Repeat your password...',
        i_accept_the: 'I accept the',
        data_privacy: 'data privacy',
        submit_registration: 'Submit registration',
        registration_sent: 'Your registration has been processed. Have a look in your mails to confirm your account and get started.',
        username_or_mail: 'Username or e-mail',
        forgot_password: 'Forgot password',
        register: 'Register'
    },
    'de': {
        title: 'StadtLandFluss.cool',
        title_long: 'Stadt Land Fluss | Online Mehrspieler',
        locale_en: 'Englisch',
        locale_de: 'Deutsch',
        create_game: 'Spiel erstellen',
        multiplayer: 'Mehrspieler',
        join_game: 'Spiel beitreten',
        public_games: 'Öffentliche Spiele',
        select_categories: 'Kategorien',
        more_suggestions: 'Mehr Vorschläge',
        own_category: 'Eigene Kategorie',
        add_own_category: 'Eigene Kategorie hinzufügen',
        add: 'Hinzufügen',
        suggestions: 'Vorschläge',
        rounds: 'Runden',
        round_end: 'Rundenende',
        round_end_stop: 'Stop drücken (mit Zeitbegrenzung)',
        round_end_timer: 'Nur Zeitbegrenzung',
        yes: 'Ja',
        no: 'Nein',
        public: 'Öffentlich',
        time_limit: 'Zeitlimit',
        minutes: 'Minuten',
        settings: 'Einstellungen',
        abort: 'Abbrechen',
        no_words_selected: 'Bitte wähle mindestens eine Kategorie',
        exit_game: 'Spiel verlassen',
        connecting: 'Verbinde zum Spiel...',
        abort: 'Abbrechen',
        lobby: 'Lobby',
        profile: 'Profil',
        players: 'Spieler',
        settings: 'Einstellungen',
        player: 'Spieler',
        you: 'Du',
        host: 'Admin',
        chat: 'Chat',
        you_are_now_admin: 'Du bist jetzt ein Admin',
        player_joined: 'Neuer Spieler beigetreten',
        input_name: 'Wie ist dein Name?',
        join: 'Beitreten',
        message: 'Nachricht',
        send: 'Senden',
        unknown_player: 'Unbekannter Spieler',
        kick: 'Rauswerfen',
        make_admin: 'Auch zum Admin machen',
        offline: 'Offline',
        kicked: 'Von einem Admin gekickt',
        to_home_page: 'Zur Startseite',
        error: 'Fehler',
        game_not_found: 'Dieses Spiel existiert nicht mehr.',
        invalid_data: 'Ungültige Daten empfangen.',
        selected: 'Ausgewählt',
        deselect: 'Entfernen',
        players_can_add_words: 'Mitspieler können Kategorien bearbeiten',
        add: 'Hinzufügen',
        close: 'Schließen',
        advanced_settings: 'Mehr Einstellungen',
        game: 'Spiel',
        rounds: 'Runden',
        end_stop: 'Stop drücken (mit Zeitlimit)',
        end_timer: 'Nach Zeitlimit',
        admin_only: 'Kann nur von einem Admin geändert werden',
        round_end: 'Rundenende',
        minutes: 'Minuten',
        public_game: 'Öffentliches Spiel',
        important_settings: 'Wichtig',
        expert_settings: 'Fortgeschritten',
        game_now_public: 'Das Spiel wird nun öffentlich aufgelistet.',
        game_now_private: 'Das Spiel ist nun privat.',
        min_done_players: 'Mindestens fertige Spieler',
        min_done_players_explain: 'Wie viele Spieler Stop drücken müssen, damit die Runde endet.',
        allow_chat: 'Chatten möglich',
        chat_disabled: 'Chatten ist deaktiviert.',
        anonymus_vote: 'Anonyme Abstimmung',
        link_title: 'Link zum Beitreten',
        copy: 'Kopieren',
        copied: 'Kopiert',
        start_game: 'Spiel starten',
        start_game_no_players: 'Es sind noch keine Mitspieler da :( Möchtest du wirklich alleine starten? Das ist bestimmt langweilig.',
        no_categories: 'Bitte wähle mindestens eine Kategorie.',
        continue: 'Fortfahren',
        game_starts_soon: 'Mach dich bereit...',
        round: 'Runde',
        of_rounds: 'von',
        game_input: 'Eingabe',
        player_progress: 'Fortschritt der Mitspieler',
        word_with: 'mit',
        done: 'Fertig',
        remaining_time: 'Verbleibende Zeit',
        thinking_about: 'Denkt nach über',
        thinking: 'Denkt nach',
        letter: 'Buchstabe',
        are_you_sure: 'Bist du sicher?',
        not_all_words: 'Du hast noch nicht alle Kategorien beantwortet.',
        answers: 'Antworten',
        voting: 'Abstimmung',
        score: 'Punktestand',
        time_is_up: 'Die Zeit ist um.',
        round_ended: 'Runde beendet',
        player_is_done: 'ist fertig.',
        reject: 'Ablehnen',
        like: 'Kreativ-Bonus',
        points: 'Punkte',
        change_letter: 'Neuen Buchstaben generieren',
        no_answers: 'Keine Antworten',
        left: 'übrig',
        likes_left: 'Deine verfügbaren Likes für diese Runde',
        search_wiki: 'Im Internet suchen',
        force_done: 'Ende erzwingen',
        force_done_desc: 'Beendet die Abstimmung - egal ob alle fertig sind.',
        marked_done: 'Als fertig markiert',
        no_public_games: 'Keine öffentlichen Spiele',
        in_game: 'Im Spiel',
        in_lobby: 'In der Lobby',
        score_mode: 'Punktestand anzeigen',
        score_live: 'Live während der Abstimmung',
        score_round: 'Nach jeder Runde',
        score_end: 'Am Ende des Spiels',
        results: 'Ergebnisse',
        final_result: 'Endergebnis',
        to_lobby: 'Fertig',
        next_round: 'Nächste Runde',
        waiting_for_admin: 'Warte auf Admin...',
        how_it_works: "So funktioniert's",
        imprint: 'Impressum',
        support_this_page: 'Diese Seite unterstützen',
        connection_closed: 'Verbindung beendet',
        kicked_message: 'Du wurdest von einem Gastgeber aus dem Spiel geworfen.',
        closed_message: 'Die Verbindung zum Server wurde geschlossen.',
        server_offline: 'Der Server ist nicht erreichbar. Wir arbeiten daran.',
        players: 'Spieler',
        games: 'Spiele',
        feedback: 'Feedback',
        feedback_text: 'Hast du Ideen, Probleme oder einfach nur Kritik zum Spiel? Schick uns eine Nachricht!',
        want_answer: 'Ich möchte eine Antwort',
        mail_address: 'Deine E-Mail',
        send: 'Senden',
        thanks_feedback: 'Vielen Dank für dein Feedback! Wir versuchen durchgehend das Spiel zu verbessern und das Feedback ist sehr hilfreich.',
        maintenance_message: 'Aktuell wird an den Servern gearbeitet. Es können zur Zeit keine Spiele erstellt werden, versuchen sie es später erneut.',
        profile_image: 'Profilbild',
        copy_url_fail: 'Der Link kann von deinem Browser nicht kopiert werden. Bitte wähle ihn manuell aus.',
        online: 'online',
        loading: 'Lädt',
        reconnecting: 'Verbindung verloren. Verbinde erneut...',
        qr_help: 'Nutzen sie eine QR-Code App oder den "Code Scannen" Knopf auf der Startseite, um über den Code beizutreten.',
        scan: 'Scannen',
        qr_scan_help: 'Scanne einen gültigen Code um dem Spiel beizutreten.',
        edit_avatar: 'Avatar bearbeiten',
        avatar_login_hint: 'Möchtest du dein Bild speichern? Mit einem StadtLandFluss Account kannst du ein eigenes Bild hochladen oder deinen Avatar speichern!',
        reset_password: 'Passwort zurücksetzen',
        password_mail: 'Bitte gebe deine E-Mail Adresse ein um dein Passwort zu ändern.',
        reset_success: 'Wir haben dir eine E-Mail mit weiteren Anweisungen geschickt.',
        reset_already_requested: 'Du kannst nur alle 10 Minuten ein neues Passwort anfordern.',
        why_account: 'Vorteile eines Accounts',
        change_password: 'Passwort ändern',
        enter_new_password: 'Gib hier dein neues Passwort ein.',
        new_password: 'Neues Passwort',
        repeat_password: 'Passwort wiederholen',
        save: 'Speichern',
        password_changed: 'Passwort aktualisiert.',
        logout: 'Abmelden',
        homepage: 'Startseite',
        change_image: 'Profilbild ändern',
        loading_image: 'Bild wird geladen...',
        load_image_error: 'Das Bild konnte nicht geladen werden. Bitte nutze eine gültige Bilddatei (.jpg, .png,...)',
        how_change_image: 'Wie möchtest du dein Bild setzen?',
        create_avatar: 'Avatar erstellen',
        upload_image: 'Eigenes Bild hochladen',
        back: 'Zurück',
        avatar_touch_edit: 'Tippe auf den Avatar um das Aussehen zu ändern.',
        avatar_mouse_edit: 'Fahre mit der Maus über den Avatar um das Aussehen zu ändern.',
        accessoriesType: "Brille",
        clotheType: "Kleidung",
        clotheColor: "Kleidungsfarbe",
        eyebrowType: "Augenbrauen",
        eyeType: "Augen",
        facialHairColor: "Bartfarbe",
        facialHairType: "Bart",
        graphicType: "Aufdruck",
        hairColor: "Haarfarbe",
        mouthType: "Mund",
        skinColor: "Haut",
        topType: "Kopfbedeckung",
        topColor: "Farbe Kopfbedeckung",
        random: "Zufällig",
        game_history: "Spielverlauf",
        game_history_label: "Hier siehst du den Verlauf deiner letzten Spiele.",
        history_empty: "Es liegen noch keine Daten vor. Wenn du ein Spiel abschließt wird es hier aufgeführt..",
        points_per_game: "Punkte pro Spiel",
        ranks: 'Platzierungen',
        level_progress: 'Level-Fortschritt',
        total: 'Imsgesamt',
        points_collected: 'Punkte gesammelt.',
        points: 'Punkte',
        delete_account: 'Account löschen',
        delete_confirm_password: 'Bitte gebe dein Passwort ein um deinen Account zu löschen.',
        account_deleted: 'Account gelöscht.',
        invalid_password: 'Ungültiges Passwort',
        privacy: 'Datenschutz',
        contact: 'Kontakt',
        contact_hint: 'Hast du ein Problem, Feedback oder einfach eine Frage? Hinterlasse uns gerne eine Nachricht!',
        contact_message: 'Deine Nachricht...',
        contact_answer: 'Ich würde mich über eine Antwort freuen',
        contact_mail: 'Deine E-Mail Adresse...',
        contact_sent: 'Deine Nachricht wurde erfolgreich gesendet.',
        login: 'Anmelden',
        username: 'Nutzername',
        your_username: 'Dein Nutzername...',
        mail_address: 'E-Mail Adresse',
        your_mail_address: 'Deine E-Mail Adresse...',
        password: 'Passwort',
        your_password: 'Dein Passwort...',
        password_characters: 'Mindestens 6 Zeichen',
        repeat_password: 'Passwort wiederholen',
        repeat_your_password: 'Wiederhole dein Passwort...',
        i_accept_the: 'Ich akzeptiere die',
        data_privacy: 'Datenschutzerklärung',
        submit_registration: 'Registrierung absenden',
        registration_sent: 'Deine Registrierung wurde verarbeitet. Schau in dein E-Mail Postfach um deinen Account zu bestätigen und loszulegen!',
        username_or_mail: 'Nutzername oder E-Mail',
        forgot_password: 'Passwort vergessen',
        register: 'Registrieren'
    }
};

const i18n = new VueI18n({ locale: 'en', messages });

export default i18n;