<template>
  <div class="game-list">
    <h3 class="heading" v-if="loading">{{ $t('loading') }}...</h3>
    <h3 class="heading" v-else>{{ $t("public_games") }}</h3>
    <b-button
      variant="outline-success"
      size="sm"
      style="position: absolute; top: 0.8em; right: 1em"
      @click="manualReload()"
      :disabled="reloadRequested"
    >
      <b-icon-arrow-repeat :class="{'reload-btn-reloading': reloadRequested}" />
    </b-button>
    <hr />
    <b-list-group class="list-view">
      <b-list-group-item
        v-for="game of publicList"
        :key="game.id"
        class="d-flex"
      >
        <h4 class="mr-2 my-auto"><b-icon-people /> {{ game.players }}</h4>
        <div>
          <div class="text-danger" v-if="game.ingame">{{ $t("in_game") }}</div>
          <div class="text-info" v-else>{{ $t("in_lobby") }}</div>
          <small class="text-muted">
            {{ game.words.join(", ") }}
          </small>
        </div>
        <b-button variant="outline-info" class="ml-auto" small @click="$emit('join', game)">
          <b-icon-play /> {{ $t("join") }}
        </b-button>
      </b-list-group-item>
      <b-badge variant="danger" v-if="publicList.length <= 0">
        {{ $t("no_public_games") }}
      </b-badge>
    </b-list-group>
    <b-badge variant="light" class="mt-2">
      {{ playerCount }} {{ $t("players") }} {{$t("online")}}
    </b-badge>
  </div>
</template>

<script>
import Client from "../../client/client";

export default {
  props: ["game"],
  data: () => {
    return {
      rawListData: [],
      reloadRequested: false,
      loading: false,
      unlocked: false
    };
  },
  mounted() {
    if(localStorage.getItem('pvrtlst')) {
      this.unlocked = true;
    }
  },
  computed: {
    publicList() {
      const list = [];
      for (const entry of this.rawListData) {
        if (entry.public || (this.unlocked && entry.players > 0)) {
          list.push(entry);
        }
      }
      return list;
    },
    gameCount() {
      return this.rawListData.length;
    },
    playerCount() {
      let count = 0;
      for (const entry of this.rawListData) {
        count += entry.players;
      }
      return count;
    },
  },
  methods: {
    reload() {
      const start = Date.now();
      this.loading = true;
      Client.getGameList().then((list) => {
        this.rawListData = list;
        this.loading = false;
      });
    },
    manualReload() {
      this.reloadRequested = true;
      setTimeout(() => {
        this.reloadRequested = false;
      }, 10000);
      this.reload();
    },
  },
};
</script>

<style scoped>
.game-list {
  width: 600px;
  max-width: 95vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 1em;
  box-shadow: 0 0 2em black;
  padding: 0.5em;
}
.heading {
  text-align: center;
  margin: 0;
}
.list-view {
  max-height: 600px;
  overflow-y: auto;
}
.reload-btn-reloading {
  animation: reload-animation 2s linear;
}

@keyframes reload-animation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(720deg);
  }
}
</style>