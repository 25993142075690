<template>
  <div class="bg bg-1">
    <NavBar
      :title="$t('title')"
      :items="menuItems"
      @select="menuSelect($event)"
    />
    <PingView :ping="game.ping" />
    <PageTitle :text="$t('answers') + ' ' + $t('round') + ' ' + game.round.number" />
    <Chat :game="game" />
    <b-row>
      <b-col class="main-panel" :class="{'mx-auto': game.settings.scoreMode !== 'live'}">
        <VoteInput :game="game" />
      </b-col>
      <b-col class="side-panel" v-if="game.settings.scoreMode === 'live'">
        <VoteScore :game="game" :live="true" :final="false"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import PingView from "../components/generic/PingView";
import PageTitle from "../components/generic/PageTitle";
import Chat from "../components/generic/Chat";
import VoteInput from "../components/vote/VoteInput";
import VoteScore from "../components/vote/VoteScore";

export default {
  name: "VotePage",
  props: ["game"],
  data: () => {
    return {
      menuItems: [
        {
          label: "exit_game",
          icon: "box-arrow-left",
          action: "exit",
        },
      ],
    };
  },
  components: {
    NavBar,
    PageTitle,
    PingView,
    Chat,
    VoteInput,
    VoteScore,
  },
  methods: {
    menuSelect(item) {
      switch (item.action) {
        case "exit":
          this.exit();
          break;
      }
    },
    exit() {
      this.$parent.exitGame();
    },
  },
};
</script>
<style scoped>
</style>
