<template>
  <div>
    <AvatarEditDialog ref="avatarEdit" @input="editAvatar($event)"/>
    <b-list-group>
      <PlayerListEntry
        :game="game"
        v-for="player of playerList"
        :key="player.id"
        :player="player"
        @select="selectedPlayer = player"
        @deselect="selectedPlayer = undefined"
        :selected="selectedPlayer === player"
        @editAvatar="$refs.avatarEdit.show($event)"
      />
    </b-list-group>
  </div>
</template>

<script>
import PlayerListEntry from "./PlayerListEntry";
import DropdownDialog from "../generic/dropdown/DropdownDialog";
import DropdownItem from "../generic/dropdown/DropdownItem";
import AvatarEditDialog from "../profile/avatar/AvatarEditDialog.vue";

export default {
  name: "PlayerList",
  components: {
    DropdownDialog,
    DropdownItem,
    PlayerListEntry,
    AvatarEditDialog
  },
  props: ["game"],
  data: () => {
    return {
      selectedPlayer: undefined,
    };
  },
  computed: {
    playerList() {
      let online = [];
      let offline = [];
      for (let id in this.game.playerList) {
        if (this.game.playerList[id].conn === "on") {
          online.push(this.game.playerList[id]);
        } else if(this.game.playerList[id].conn === 'kick') {
          offline.push(this.game.playerList[id]);
        }
      }
      online.push(...offline);
      return online;
    },
  },
  methods: {
    editAvatar(avatar) {
      this.game.updateAvatar(avatar);
    }
  }
};
</script>

<style scoped>
</style>
