<template>
    <div>
        Usage Terms
    </div>
</template>

<script>
export default {
    name: 'UsageTerms'
}
</script>