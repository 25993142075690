<template>
  <div>
    <div class="avatar-display">
      <AvatarDisplay :avatar="avatar" />
      <div class="edit-buttons">
        <AvatarPropertyEdit
          property="accessoriesType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 38%; left: 48%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="clotheType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 78%; left: 48%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="clotheColor"
          :avatar="avatar"
          v-if="isColorableClothe"
          class="prop-edit"
          style="top: 80%; left: 75%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="eyebrowType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 30%; left: 60%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="eyeType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 38%; left: 36%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="facialHairColor"
          :avatar="avatar"
          v-if="avatar.facialHairType !== 'Blank'"
          class="prop-edit"
          style="top: 58%; left: 62%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="facialHairType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 62%; left: 50%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="graphicType"
          :avatar="avatar"
          v-if="avatar.clotheType === 'GraphicShirt'"
          class="prop-edit"
          style="top: 90%; left: 50%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="hairColor"
          :avatar="avatar"
          v-if="isColorableHair"
          class="prop-edit"
          style="top: 20%; left: 30%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="mouthType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 52%; left: 50%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="skinColor"
          :avatar="avatar"
          class="prop-edit"
          style="top: 25%; left: 50%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="topType"
          :avatar="avatar"
          class="prop-edit"
          style="top: 18%; left: 50%"
          @advance="advance($event)"
        />
        <AvatarPropertyEdit
          property="topColor"
          :avatar="avatar"
          v-if="hasHat"
          class="prop-edit"
          style="top: 18%; left: 65%"
          @advance="advance($event)"
        />
      </div>
    </div>
    <p v-if="isTouch">{{ $t('avatar_touch_edit') }}</p>
    <p v-else>{{ $t('avatar_mouse_edit') }}</p>
    <div class="d-flex" style="justify-content: space-between">
      <b-btn
        @click="generateRandom()"
        variant="info"
        :disabled="randomTask !== undefined"
      >
        <b-icon-dice-6 /> {{ $t('random') }}
      </b-btn>
      <b-btn
        @click="$emit('save')"
        variant="success"
      >
        <b-icon-check /> {{ $t('save') }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import AvatarDisplay from "./AvatarDisplay";
import AvatarPropertyEdit from "./AvatarPropertyEdit";
const AvatarData = require("../../../../../shared/avatar");

export default {
  name: "AvatarCustomizer",
  props: ["avatarData", "noCache"],
  data: () => ({
    avatar: AvatarData.generateRandom(),
    isTouch: "ontouchstart" in window || navigator.msMaxTouchPoints,
    randomTask: undefined,
    randomDelay: 0,
  }),
  components: {
    AvatarDisplay,
    AvatarPropertyEdit,
  },
  created() {
    if (this.avatarData) {
      this.avatar = { ...this.avatarData };
    }
    this.$emit("avatarChanged", this.avatar);
  },
  computed: {
    props() {
      return Object.keys(AvatarData.props);
    },
    propCount() {
      return this.props.length;
    },
    hasHat() {
      return AvatarData.isHatTop(this.avatar.topType);
    },
    isColorableClothe() {
      return AvatarData.isColorableClothe(this.avatar.clotheType);
    },
    isColorableHair() {
      return AvatarData.isColorableHair(this.avatar.topType);
    },
  },
  methods: {
    generateRandom() {
      this.randomDelay = 30;
      this.executeRandomTask();
    },
    executeRandomTask() {
      if (this.randomDelay < 200) {
        this.randomTask = setTimeout(this.executeRandomTask, this.randomDelay);
        this.randomDelay *= 1.1;
        this.setAvatar(AvatarData.generateRandom());
      } else {
        this.randomTask = undefined;
      }
    },
    setAvatar(avatarData) {
      this.avatar = avatarData;
      if (!this.noCache) {
        localStorage.setItem("avatar", JSON.stringify(avatarData));
      }
      this.$emit("avatarChanged", avatarData);
    },
    advance(property) {
      this.setAvatar(AvatarData.advanceProperty(this.avatar, property));
    },
  },
};
</script>

<style scoped>
.avatar-display {
  position: relative;
  user-select: none;
}
.avatar-display:hover .prop-edit {
  opacity: 1;
  transform: scale(1);
}
.prop-edit {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: 300ms;
}
</style>