import { render, staticRenderFns } from "./AvatarPropertyEdit.vue?vue&type=template&id=566d2160&scoped=true&"
import script from "./AvatarPropertyEdit.vue?vue&type=script&lang=js&"
export * from "./AvatarPropertyEdit.vue?vue&type=script&lang=js&"
import style0 from "./AvatarPropertyEdit.vue?vue&type=style&index=0&id=566d2160&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566d2160",
  null
  
)

export default component.exports