<template>
  <div class="bg bg-1">
    <div class="center-overlay" :class="{ closed: sumbitted }">
      <h2>{{ $t("input_name") }}</h2>
      <b-form-input
        type="text"
        v-model="currentName"
        maxlength="30"
        @keydown="onKeydown($event)"
      ></b-form-input>
      <div class="hcenter mt-3">
        <b-button variant="danger" @click="exit()">
          {{ $t("abort") }}
        </b-button>
        <b-button
          variant="success"
          style="float: right"
          @click="join()"
          :disabled="currentName.length < 2"
        >
          <b-icon-play-fill />
          {{ $t("join") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../plugins/i18n";
import Client from "../client/client";

export default {
  name: "NameInputDialog",
  data: () => {
    return {
      currentName: "",
      sumbitted: false,
      nameSuggestionData: {
        de: {
          first_male: [
            "Toller",
            "Cooler",
            "Epischer",
            "Fantastischer",
            "Unschlagbarer",
            "Fabulöser",
            "Unbesiegbarer",
            "Unantastbarer",
            "Allwissender",
            "Mächtiger",
            "Magischer",
            "Starker",
            "Lustiger",
            "Tapferer",
            "Allmächtiger",
            "Legendärer",
          ],
          second_male: [
            "Dinosaurier",
            "Ritter",
            "Meister",
            "Superheld",
            "Baum",
            "Bösewicht",
            "Schneemann",
            "Pirat",
            "Professor",
            "Yeti",
          ],
          first_female: [
            "Mächtige",
            "Unbesiegbare",
            "Fabulöse",
            "Fantastische",
            "Phänomenale",
            "Starke",
            "Intelligente",
            "Allmächtige",
            "Legendäre",
          ],
          second_female: [
            "Palme",
            "Prinzessin",
            "Topfblume",
            "Dinosaurierin",
            "Königin",
            "Piratin",
            "Superheldin",
            "Professorin",
          ],
        },

        en: {
          first_male: [
            "Nice",
            "Epic",
            "Fancy",
            "Fabulous",
            "Magic",
            "Untouchable",
            "Intelligent",
            "Legendary",
          ],
          second_male: [
            "Dinosaur",
            "Princess",
            "Master",
            "Genius",
            "Superhero",
            "Lion",
            "Tiger",
            "Tree",
            "Elephant",
            "Flower",
          ],
        },
      },
    };
  },
  created() {
    if (Client.session) {
      if(Client.app.profileData.name) {
        this.currentName = Client.app.profileData.name;
      } else {
        Client.profileLoading.then(() => {
          this.currentName = Client.app.profileData.name;
        });
      }
      //this.join();
    } else {
      if (localStorage.getItem("last_picked_name")) {
        this.currentName = localStorage.getItem("last_picked_name");
      } else {
        this.currentName = this.generateRandomName();
      }
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    exit() {
      this.$parent.exitGame();
    },
    join() {
      this.sumbitted = true;
      setTimeout(() => {
        localStorage.setItem("last_picked_name", this.currentName);
        this.$parent.setAuthName(this.currentName);
      }, 300);
    },
    onKeydown(event) {
      if (event.key === "Enter" && this.currentName.length >= 2) {
        this.join();
      }
    },
    generateRandomName() {
      const data = this.nameSuggestionData[i18n.locale];
      if (data) {
        const hasFemale = data["first_female"] !== undefined;
        let first = data["first_male"];
        let second = data["second_male"];
        if (hasFemale && Math.random() > 0.5) {
          first = data["first_female"];
          second = data["second_female"];
        }
        return (
          first[(Math.random() * first.length) | 0] +
          " " +
          second[(Math.random() * second.length) | 0]
        );
      } else {
        return "Your name";
      }
    },
  },
};
</script>
<style scoped>
.center-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  transition: 300ms;
  width: 20em;
  max-width: 95vw;
  box-shadow: 1em 1em 2em lightgray;
}

.closed {
  transform: translate(-50%, -50%) scale(0);
}

.rotate-icon {
  animation: rotation linear infinite 1s;
  width: fit-content;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
