<template>
  <b-navbar toggleable="lg" type="dark" variant="info" id="navbar">
    <b-navbar-brand href="#"><b-icon-pencil /> {{ $t('title') }}</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          v-for="(item, index) of items"
          :key="index"
          href="#"
          @click="$emit('select', item)"
        >
          <b-icon :icon="item.icon" /> {{ $t(item.label) }}
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <slot>
        
        </slot>
        <LanguageSelector />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import i18n from "@/plugins/i18n";
import LanguageSelector from "./LanguageSelector";

export default {
  name: "NavBar",
  props: ["items", "title"],
  components: {
    LanguageSelector,
  },
  methods: {
    setLocale(locale) {
      i18n.locale = locale;
    },
  },
  computed: {
    localeId() {
      return i18n.locale;
    },
    localeFlagIso() {
      switch (i18n.locale) {
        case "en":
          return "us";
        default:
          return i18n.locale;
      }
    },
  },
};
</script>

<style scoped>
.support-button {
  transform: scale(0.8);
  width: 150px;
}
</style>
