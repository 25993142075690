<template>
  <b-popover
    show.sync="show"
    :target="target"
    variant="danger"
    triggers="click blur"
  >
    <template #title>{{ $t("profile_image") }}</template>
    <b-row>
      <img
        v-for="image of images"
        :src="image.url"
        :key="image.id"
        @click="
          $emit('select', image.id);
          show = false;
        "
      />
    </b-row>
  </b-popover>
</template>


<script>
export default {
  name: "ProfileImageSelector",
  props: ["target", "game"],
  data: () => {
    return {
      show: false,
    };
  },
  computed: {
    images() {
      const list = [];
      const players = Object.values(this.game.playerList);
      for (let i = 0; i < 20; i++) {
        let used = false;
        if (players.length < 20) {
          for (const player of players) {
            if (player.avatar === i) {
              used = true;
              break;
            }
          }
        }
        if (!used) {
          list.push({ id: i, url: require(`../../assets/avatars/${i}.png`) });
        }
      }

      return list;
    },
  },
};
</script>

<style scoped>
img {
  width: 2.9em;
  cursor: pointer;
  margin: 0.5em;
  transition: 200ms;
  border-radius: 50%;
  border: 3px solid transparent;
}
img:hover {
  filter: brightness(1.1);
  border: 3px solid greenyellow;
}
</style>