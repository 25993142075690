<template>
  <div class="center-info-panel mx-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CenterInfoPanel",
};
</script>

<style scoped>
.center-info-panel {
  background-color: rgba(255, 255, 255, 0.8);
  width: fit-content;
  padding: 0.5em;
  border-radius: 0.5em;
  max-width: 95vw;
  border: 2px solid black;
  box-shadow: 0 0 1em black;
}
</style>