<template>
<div v-b-tooltip.hover :title="`Level ${level}`">
    <b-badge pill v-if="badge" :style="{'background-color': background[medal]}">
        {{level}}
    </b-badge>
  <div v-else class="level-badge" :class="{ large: large, small: !large }" >
    <div class="medal" :class="medal">
      <div>{{ level }}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "LevelBadge",
  props: ["points", "large", "badge"],
  data: () => ({
      background: {
          'bronze': '#c79664',
          'silver': '#bbbbbb',
          'gold': '#f7d100'
      }
  }),
  computed: {
    level() {
      let levelId = 1;
      for (
        let pointLimit = 50;
        pointLimit < 1000000;
        pointLimit *= 1.5
      ) {
        if (this.points < pointLimit) {
          return levelId;
        }
        levelId++;
      }
      return levelId;
    },
    medal() {
      if (this.level < 20) {
        return "bronze";
      } else if (this.level < 50) {
        return "silver";
      } else {
        return "gold";
      }
    },
  },
};
</script>

<style scoped>
.medal {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-width: .4em;
    border-style: solid;
}
.bronze {
  background-color: #c79664;
  border-color: #ae7640;
}
.silver {
  background-color: #bbbbbb;
  border-color: #9e9e9e;
}
.gold {
  background-color: #f7d100;
  border-color: #e1c100;
}
.level-badge > div {
  margin-top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 1.5rem;
}
.large {
  width: 3rem;
  height: 3rem;
}
.small {
  width: 1.5rem;
  height: 1.5rem;
}
</style>