<template>
  <div class="bg bg-1">
    <NavBar
      :title="$t('title')"
      :items="menuItems"
      @select="menuSelect($event)"
    />
    <PingView :ping="game.ping" />
    <PageTitle
      :text="
        $t('round') +
        ' ' +
        game.round.number +
        ' ' +
        $t('of_rounds') +
        ' ' +
        game.settings.rounds
      "
    />
    <b-row>
      <b-col class="main-panel">
        <GameInput :game="game" />
      </b-col>
      <b-col class="side-panel">
        <PlayerProgress class="player-progress-panel" :game="game" />
      </b-col>
    </b-row>
    <Chat :game="game" />
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import PingView from "../components/generic/PingView";
import PageTitle from "../components/generic/PageTitle";
import Chat from "../components/generic/Chat";
import CenterInfoPanel from "../components/generic/panel/CenterInfoPanel";
import GameInput from "../components/game/GameInput";
import PlayerProgress from "../components/game/PlayerProgress";
import i18n from "../plugins/i18n";

export default {
  name: "GamePage",
  props: ["game"],
  data: () => {
    return {
      menuItems: [
        {
          label: "exit_game",
          icon: "box-arrow-left",
          action: "exit",
        },
      ],
    };
  },
  components: {
    NavBar,
    PageTitle,
    PingView,
    Chat,
    CenterInfoPanel,
    GameInput,
    PlayerProgress,
  },
  methods: {
    menuSelect(item) {
      switch (item.action) {
        case "exit":
          this.exit();
          break;
      }
    },
    exit() {
      this.$parent.exitGame();
    },
  },
};
</script>
<style scoped>
.large-letter {
  font-size: 4rem;
  font-weight: bold;
}
</style>
