<template>
  <div>
    <input
      type="file"
      class="mb-2"
      accept="image/*"
      @input="onFileInput($event)"
    />
    <b-badge variant="danger" v-if="loadError"
      >{{ $t('load_image_error') }}</b-badge
    >
    <b-badge v-if="loadingImage">{{ $t('loading_image') }}</b-badge>
    <ImageCropper
      class="mb-2"
      :image="image"
      v-if="canvasReady"
      ref="imageCropper"
    />
    <div class="d-flex" style="justify-content: space-between">
      <div></div>
      <b-btn variant="success" @click="$emit('save')" v-if="canvasReady">
        <b-icon-check /> {{ $t('save') }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import ImageCropper from "./ImageCropper";

export default {
  name: "ImageUploader",
  data: () => ({
    image: new Image(),
    canvasReady: false,
    loadError: false,
    imageCrop: undefined,
    loadingImage: false,
    imageSize: 256,
  }),
  components: {
    ImageCropper,
  },
  methods: {
    onReadImage(event) {
      if (event.target.readyState == FileReader.DONE) {
        this.image.src = event.target.result;
        this.image.onload = this.onLoadImage;
        this.image.onerror = () => {
            this.canvasReady = false;
            this.loadError = true;
            this.loadingImage = false;
        }
      }
    },
    onLoadImage() {
      this.canvasReady = true;
      this.loadError = false;
      this.loadingImage = false;
    },
    onFileInput(event) {
        this.loadingImage = true;
        this.canvasReady = false;
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = this.onReadImage;
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    getImageData() {
      const dataURL = this.$refs.imageCropper.getCroppedImageData(
        this.imageSize
      );
      return dataURL;
    },
  },
};
</script>