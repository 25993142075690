import { render, staticRenderFns } from "./GameInput.vue?vue&type=template&id=0c2a1132&scoped=true&"
import script from "./GameInput.vue?vue&type=script&lang=js&"
export * from "./GameInput.vue?vue&type=script&lang=js&"
import style0 from "./GameInput.vue?vue&type=style&index=0&id=0c2a1132&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2a1132",
  null
  
)

export default component.exports