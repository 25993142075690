import { render, staticRenderFns } from "./PlayerList.vue?vue&type=template&id=febe142a&scoped=true&"
import script from "./PlayerList.vue?vue&type=script&lang=js&"
export * from "./PlayerList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "febe142a",
  null
  
)

export default component.exports