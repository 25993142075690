<template>
  <div class="bg bg-1" :class="{'black-white': !centerHover}">
    <div class="center-overlay" @mouseenter="centerHover=true" @mouseleave="centerHover=false">
      <h2><b-icon-emoji-frown /> {{ $t("error") }}</h2>
      <div>{{$t(message)}}</div>
      <div class="hcenter mt-3">
        <b-button variant="info" @click="home()">
          <b-icon-play-fill />
          {{ $t("to_home_page") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  props: ["message"],
  data: () => {
      return {
          centerHover: false
      }
  },
  methods: {
    home() {
      this.$parent.exitGame();
    },
  },
};
</script>
<style scoped>

.closed {
  transform: translate(-50%, -50%) scale(0);
}

.bg {
    transition: 300ms;
}
.black-white {
  filter: grayscale();
}
</style>
