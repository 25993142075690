<template>
  <div class="time-bar">
    <div
      class="bar-inner"
      :class="{
        'bg-success': currentPercentage > 80,
        'bg-info': currentPercentage > 20,
        'bg-danger': currentPercentage <= 20,
      }"
      :style="{ width: currentPercentage + '%' }"
    >
      <div class="time-label">
        {{ currentTime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeBar",
  props: ["game"],
  computed: {
    currentPercentage() {
      return (
        (this.game.round.remainingTime / (this.game.settings.timeLimit * 60)) *
        100
      );
    },
    currentTime() {
      if (this.game.round.remainingTime > 60) {
        return (
          ((this.game.round.remainingTime / 60) | 0) +
          "min " +
          (this.game.round.remainingTime % 60 | 0).toString().padStart(2, '0') + 's'
        );
      } else {
        return this.game.round.remainingTime;
      }
    },
  },
};
</script>

<style scoped>
.time-bar {
  width: 100%;
  height: 3em;
  position: relative;
  border: 2px solid black;
  border-radius: 1.5em;
}
.bar-inner {
  height: 100%;
  position: absolute;
  border-radius: 1.5em;
  top: 0;
  left: 0;
  transition: 1s;
  animation-timing-function: linear;
}
.time-label {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.25rem;
  margin-top: 0.5rem;
  border-radius: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
}
</style>
