<template>
  <b-card class="vote-input">
    <template #header>
      <h5 style="float: left"><b-icon-card-checklist /> {{ $t("voting") }}</h5>
      <b-badge
        variant="info"
        style="float: right"
        v-b-tooltip.hover
        :title="$t('likes_left')"
      >
        <b-icon-hand-thumbs-up />x {{ likesLeft }} {{ $t("left") }}
      </b-badge>
    </template>
    <div
      class="card mb-1 word-card"
      v-for="word of game.settings.words"
      :key="word"
    >
      <div class="card-header bg-success">
        <h5 class="text-white">{{ word }}</h5>
      </div>

      <div class="card-body">
        <b-list-group v-if="getAnswers(word).length > 0">
          <b-list-group-item
            v-for="answer in getAnswers(word)"
            :key="answer.player.id"
            class="d-flex justify-content-between align-items-center"
          >
            <div class="d-flex">
              <ProfileImageDisplay
                :image="answer.player.userInfo.image"
                :player="answer.player"
                v-if="!game.settings.anonymus"
                size="small-icon"
              />
              <b-badge
                :class="{ 'text-danger striked-out': isRejected(answer) }"
                v-if="!game.settings.anonymus"
                variant="info"
                class="mr-2 my-auto"
                >{{ answer.player.name }}
              </b-badge>
              <b
                :class="isRejected(answer) ? 'text-danger' : 'text-info'"
                class="my-auto"
                >{{ answer.answer.content }}
              </b>
              <b-badge variant="light" class="my-auto">
                + {{ calculatePoints(word, answer) }}
              </b-badge>
            </div>
            <div class="d-flex" v-if="answer.player.id !== game.playerProfile.id"> <!--Keine Buttons für sich selbst-->
              <b-button-group>
                <b-button
                  variant="danger"
                  :class="{
                    'hover-button': answer.player.id === game.playerProfile.id,
                  }"
                  :id="`reject-${answer.player.id}-${word}`"
                  @click="reject(answer.player.id, word)"
                  size="sm"
                >
                  <CustomSvgIcon
                    icon="hand-thumbs-down-fill"
                    v-if="hasRejected(answer)"
                  />
                  <b-icon-hand-thumbs-down v-else />
                  {{ answer.answer.reject.length }}/{{ neededToReject }}
                </b-button>
                <b-button
                  variant="info"
                  :class="{
                    'hover-button': answer.player.id === game.playerProfile.id,
                  }"
                  :id="`like-${answer.player.id}-${word}`"
                  :disabled="
                    likesLeft <= 0 &&
                    !answer.answer.like.includes(game.playerProfile.id)
                  "
                  @click="like(answer.player.id, word)"
                  size="sm"
                >
                  <CustomSvgIcon
                    icon="hand-thumbs-up-fill"
                    v-if="hasLiked(answer)"
                  />
                  <b-icon-hand-thumbs-up v-else />
                  {{ answer.answer.like.length }}
                </b-button>
                <b-button
                  :id="`wiki-${answer.player.id}-${word}`"
                  variant="secondary"
                  @click="openWiki(answer)"
                >
                  <b-icon-search />
                </b-button>
              </b-button-group>
            </div>
            <b-popover
              :target="`reject-${answer.player.id}-${word}`"
              triggers="hover"
              placement="top"
            >
              <b class="text-danger">{{ $t("reject") }}</b>
              <div class="d-flex">
                <div v-for="voter in answer.answer.reject" :key="voter">
                  <ProfileImageDisplay
                    size="tiny"
                    :image="game.playerList[voter].userInfo.image"
                    :player="game.playerList[voter]"
                    v-b-tooltip.hover
                    :title="game.playerList[voter].name"
                  />
                </div>
              </div>
            </b-popover>
            <b-popover
              :target="`like-${answer.player.id}-${word}`"
              triggers="hover"
              placement="top"
            >
              <b class="text-info">{{ $t("like") }}</b>
              <div class="d-flex">
                <div v-for="voter in answer.answer.like" :key="voter">
                  <ProfileImageDisplay
                    size="tiny"
                    :image="game.playerList[voter].userInfo.image"
                    :player="game.playerList[voter]"
                    v-b-tooltip.hover
                    :title="game.playerList[voter].name"
                  />
                </div>
              </div>
            </b-popover>
            <b-popover
              :target="`wiki-${answer.player.id}-${word}`"
              triggers="hover"
              placement="top"
            >
              <b class="text-secondary">{{ $t("search_wiki") }}</b>
            </b-popover>
          </b-list-group-item>
        </b-list-group>
        <b-badge variant="danger" v-if="getAnswers(word).length <= 0">
          {{ $t("no_answers") }}
        </b-badge>
      </div>
    </div>
    <hr />
    <div style="float: right">
      <b-button
        variant="danger"
        @click="forceRestart()"
        v-if="game.playerProfile.admin"
        class="mr-1"
        >
        <b-icon-arrow-counterclockwise /> Runde neu starten
      </b-button>
      <b-button
        variant="danger"
        @click="forceDone()"
        v-if="game.playerProfile.admin"
        class="mr-1"
        v-b-tooltip.hover
        :title="$t('force_done_desc')"
      >
        <b-icon-exclamation-circle /> {{ $t("force_done") }}
      </b-button>
      <b-button variant="success" @click="markDone()" id="done-button">
        <b-icon-check /> {{ isDone ? $t("marked_done") : $t("done") }} ({{
          doneCount
        }}/{{ this.game.onlinePlayerCount() }})
      </b-button>
      <b-popover target="done-button" triggers="hover" placement="top">
        <b>{{ $t("done") }}</b>
        <div class="d-flex">
          <div v-for="donePlayer in game.roundResults.done" :key="donePlayer">
                  <ProfileImageDisplay
                    size="tiny"
                    :image="game.playerList[donePlayer].userInfo.image"
                    :player="game.playerList[donePlayer]"
                    v-b-tooltip.hover
                    :title="game.playerList[donePlayer].name"
                  />
          </div>
        </div>
      </b-popover>
    </div>
  </b-card>
</template>

<script>
import CustomSvgIcon from "../generic/CustomSvgIcon";
import PointCalculator from "../../../../shared/points";
import ProfileImageDisplay from "../profile/ProfileImageDisplay";

export default {
  name: "VoteInput",
  props: ["game"],
  components: {
    CustomSvgIcon,
    ProfileImageDisplay,
  },
  computed: {
    neededToReject() {
      return PointCalculator.calculateRejectThreshold(
        this.game.onlinePlayerCount(),
        this.game.settings.neededToReject
      );
    },
    likesLeft() {
      if (this.game.roundResults.likes) {
        return (
          this.game.settings.likesPerWord * this.game.settings.words.length -
          (this.game.roundResults.likes[this.game.playerProfile.id]
            ? this.game.roundResults.likes[this.game.playerProfile.id]
            : 0)
        );
      } else {
        return 0;
      }
    },
    isDone() {
      return (
        this.game.roundResults.done &&
        this.game.roundResults.done.includes(this.game.playerProfile.id)
      );
    },
    doneCount() {
      return this.game.roundResults.done
        ? this.game.roundResults.done.length
        : 0;
    },
  },
  methods: {
    getAnswers(word) {
      const list = [];
      for (const player in this.game.roundResults.answers) {
        if (
          this.game.roundResults.answers[player][word] &&
          this.game.roundResults.answers[player][word].content.length > 1
        ) {
          list.push({
            player: this.game.playerList[player],
            answer: this.game.roundResults.answers[player][word],
          });
        }
      }
      return list;
    },
    forceRestart() {
      this.game.restartRound();
    },
    getAvatar(player) {
      return require(`../../assets/avatars/${player.avatar}.png`);
    },
    openWiki(answer) {
      /**
      window.open(
        `https://de.wikipedia.org/wiki/${answer.answer.content}`,
        "_blank"
      );
      */
      window.open(
        `https://www.ecosia.org/search?q=${answer.answer.content}`,
        "_blank"
      );
    },
    isRejected(answer) {
      return answer.answer.reject.length >= this.neededToReject;
    },
    reject(playerId, word) {
      if (playerId !== this.game.playerProfile.id) {
        this.game.rejectAnswer(playerId, word);
      }
    },
    like(playerId, word) {
      if (playerId !== this.game.playerProfile.id) {
        this.game.likeAnswer(playerId, word);
      }
    },
    hasRejected(answer) {
      return answer.answer.reject.includes(this.game.playerProfile.id);
    },
    hasLiked(answer) {
      return answer.answer.like.includes(this.game.playerProfile.id);
    },
    getAvatarById(playerId) {
      return require("../../assets/avatars/" +
        this.game.playerList[playerId].avatar +
        ".png");
    },
    calculatePoints(word, answer) {
      return PointCalculator.calculateFullPoints(
        this.game.settings.points,
        this.game.roundResults.answers,
        word,
        answer.answer.content,
        answer.answer.like.length,
        answer.answer.reject.length,
        answer.player.id,
        this.neededToReject
      );
    },
    markDone() {
      if (!this.isDone) {
        this.game.markVoteDone();
      }
    },
    forceDone() {
      this.game.forceVoteDone();
    },
  },
};
</script>

<style scoped>
@media (max-width: 670px) {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.popover-avatar {
  width: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.striked-out {
  text-decoration: line-through;
}

.answer-avatar {
  width: 2em;
  margin-right: 0.5em;
}

.hover-button {
  cursor: default !important;
  filter: grayscale();
  background-color: rgb(100, 100, 100) !important;
  transition: 0;
}
.hover-button:hover {
  background-color: rgb(100, 100, 100) !important;
}
</style>
