
const PointCalculator = {
    //Calculates base points for a single answer (is it the only answer or does it exist multiple times)
    calculateBasePoints(pointConfig,
        answers,
        word,
        answer,
        playerId,
        rejectThreshold) {
        if (!answer || answer.length <= 1) {
            return 0;
        }
        let only = true;
        let single = true;
        const lower = answer.toLowerCase().trim();
        for (let id in answers) {
            if (id !== playerId && 
                    answers[id] !== undefined &&
                    answers[id][word] !== undefined && 
                    answers[id][word].content.length > 1 && 
                    answers[id][word].reject.length < rejectThreshold) {

                only = false;
                if (answers[id][word].content.toLowerCase().trim() === lower) {
                    single = false;
                    break;
                }
            }
        }
        if (only) {
            return pointConfig.only;
        } else if (single) {
            return pointConfig.single;
        } else {
            return pointConfig.multi;
        }
    },
    //Calculates the points for a single answer, including likes & rejection
    calculateFullPoints(pointConfig,
        answers,
        word,
        answer,
        likes,
        rejects,
        playerId,
        rejectThreshold) {
        if (rejects >= rejectThreshold) {
            return 0;
        } else {
            return PointCalculator.calculateBasePoints(pointConfig, answers, word, answer, playerId, rejectThreshold) + pointConfig.like * likes;
        }
    },
    //Calculates total points for user by iterating all answers
    calculateTotalPoints(pointConfig,
        answers,
        playerId,
        rejectThreshold) {
        let total = 0;
        for (const word in answers[playerId]) {
            total += this.calculateFullPoints(pointConfig, answers, word, answers[playerId][word].content, answers[playerId][word].like.length, answers[playerId][word].reject.length, playerId, rejectThreshold);
        }
        return total;
    },
    //Calculates the amount of votes needed for rejection, based on the current player count.
    calculateRejectThreshold(onlinePlayers, percentageNeeded) {
        if (onlinePlayers <= 2) {
            return 1;
        }
        const value =
            (onlinePlayers *
                (percentageNeeded * 0.01)) |
            0;
        return value < 1 ? 1 : value + 1;
    }
}

module.exports = PointCalculator;