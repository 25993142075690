<template>
  <div class="bg bg-1">
    <NavBar :title="$t('title')" />
    <b-row class="mt-5">
      <b-card
        class="mx-auto"
        style="max-width: 600px"
        :title="$t('change_password')"
      >
        <p>{{ $t("enter_new_password") }}:</p>
        <b-form-input
          class="mt-0"
          @keydown.enter="confirm()"
          v-model="pass"
          type="password"
          :placeholder="$t('new_password')"
          trim
        ></b-form-input>
        <br />
        <b-form-input
          class="mt-0"
          v-model="repeat"
          @keydown.enter="confirm()"
          type="password"
          :placeholder="$t('repeat_password')"
          trim
        ></b-form-input>
        <b-btn @click="confirm()" class="btn-success mt-2" style="float: right;" :disabled="!valid">
          <b-icon-check /> {{ $t("save") }}
        </b-btn>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar.vue";
import Client from "../client/client";

export default {
  name: "ChangePasswordPage",
  props: ['secret'],
  data: () => ({
      pass: '',
      repeat: ''
  }),
  components: {
    NavBar,
  },
  computed: {
      valid() {
          return this.pass.length >= 6 && this.pass === this.repeat;
      }
  },
  methods: {
      confirm() {
          if(this.valid) {
              Client.resetPassword(this.secret, this.pass).then(response => {
                  console.log(response);
                  if(response.success) {
                      this.$alert(this.$i18n.t('password_changed'), '', 'success');
                      this.$parent.showPasswordReset = false;
                      this.$parent.showUserProfile = true;
                      window.location.hash = '';
                  } else if(response.error === 'invalid-key') {
                      this.$alert(this.$i18n.t('password_link_invalid'), this.$i18n.t('error'), 'error');
                      this.$parent.showPasswordReset = false;
                      window.location.hash = '';
                  }
              });
          }
      }
  }
};
</script>