<template>
  <b-card class="player-progress mb-2">
    <template #header>
      <h5><b-icon-people /> {{ $t("player_progress") }}</h5>
    </template>
    <div
      class="player-progress-view"
      :style="{ height: list.length * 4 + 'em' }"
    >
      <div
        class="player-progress-item"
        :style="{ top: index * 4 + 'em', 'z-index': list.length - index }"
        v-for="(player, index) in list"
        :key="player.player.id"
      >
        <div class="d-flex">
          <!--
          <div class="avatar">
            <img :src="player.avatar" class="avatar-img" />
            <div class="avatar-label">{{ player.data.progress }}</div>
          </div>
          -->
          <ProfileImageDisplay :image="player.player.userInfo.image" :player="player.player" size="icon">
            <div class="avatar-label">{{ player.data.progress }}</div>
          </ProfileImageDisplay>
          <div class="ml-1">
            <b class="my-auto">{{ player.player.name }}</b>
            <template v-if="isPlayerDone(player)">
              <div class="text-success">
                {{ $t("done") }}
              </div>
            </template>
            <template v-else>
              <div class="text-muted" v-if="player.data.thinking">
                {{ $t("thinking_about") + ": " + player.data.thinking }}
              </div>
              <div v-else class="text-muted">
                {{ $t("thinking") }}
              </div>
            </template>
          </div>
        </div>
        <div
          class="player-progress-bar bg-success"
          :style="{ width: getPlayerProgress(player) + '%' }"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import ProfileImageDisplay from "../profile/ProfileImageDisplay";

export default {
  name: "PlayerProgress",
  props: ["game"],
  components: {
    ProfileImageDisplay
  },
  computed: {
    list() {
      const list = [];
      for (const player in this.game.round.progress) {
        if (this.game.playerList[player].conn === "on") {
          list.push({
            player: this.game.playerList[player],
            avatar: require("../../assets/avatars/" +
              this.game.playerList[player].avatar +
              ".png"),
            data: this.game.round.progress[player],
          });
        }
      }
      if (list.length > 0) {
        const sorted = [];
        const count = list.length;
        for (let i = 0; i < count; i++) {
          let maxEntry = list[0];
          for (const entry of list) {
            if (entry.data.progress > maxEntry.data.progress) {
              maxEntry = entry;
            }
          }
          sorted.push(maxEntry);
          list.splice(list.indexOf(maxEntry), 1);
        }
        return sorted;
      } else {
        return list;
      }
    },
  },
  methods: {
    getPlayerProgress(player) {
      return (player.data.progress / this.game.settings.words.length) * 100;
    },
    isPlayerDone(player) {
      return this.game.round.stopped.includes(player.player.id);
    },
  },
};
</script>

<style scoped>
.player-progress-view {
  position: relative;
}
.avatar {
  height: 3em;
  position: relative;
}
.avatar-label {
  position: absolute;
  top: 0.25rem;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 0 0 0.4rem black;
}
.avatar > .avatar-img {
  height: 100%;
}
.player-progress-item {
  height: 3.6em;
  position: absolute;
  transition: 500ms;
  background-color: white;
  width: 100%;
  left: 0;
}
.player-progress-bar {
  height: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0.5rem;
  transition: 300ms;
}
.card-body {
  height: fit-content;
}
</style>
