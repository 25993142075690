<template>
  <b-modal ref="modal" :title="$t('scan')">
    <qrcode-stream @decode="onDecode"></qrcode-stream>
        <p class="text-muted mt-2">
        {{ $t('qr_scan_help') }}
    </p>
    <template #modal-footer>
        <b-button variant="primary" small @click="hide()">
            {{ $t('close') }}
        </b-button>
    </template>
  </b-modal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  name: "QRScanModal",
  components: {
      QrcodeStream
  },
  methods: {
      show() {
          this.$refs.modal.show();
      },
      hide() {
          this.$refs.modal.hide();
      },
      onDecode(data) {
          if(data.includes(window.location.origin)) {
              this.hide();
              window.location.href = data;
              window.location.reload();
          }
      }
  }
};
</script>