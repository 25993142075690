<template>
  <div class="discord-widget">
    <div class="click-catcher" @click="join()"/>
    <iframe
      src="https://discord.com/widget?id=793605826358542376&theme=dark"
      width="350"
      height="500"
      allowtransparency="true"
      class="frame"
      @click.stop="join()"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    ></iframe>
    <button
      class="join-button"
    >
      <b-icon-box-arrow-in-right /> {{ $t("join") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "DiscordWidget",
  methods: {
      join() {
          window.open('https://discord.gg/snZfmg8GGF', '_blank');
      }
  }
};
</script>

<style scoped>
.discord-widget {
  width: fit-content;
  display: grid;
}
.frame {
  max-height: 4.5em;
}
.join-button {
  border: none;
  border-top: 1px solid white;
  background-color: #7289da;
  color: white;
  transition: 100ms;
}
.join-button:hover {
  filter: brightness(1.2);
}
.click-catcher {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
</style>