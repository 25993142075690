<template>
<div>
    <div v-if="painting">Grafik wird erzeugt...</div>
    <canvas ref="canvas"></canvas>
</div>
</template>

<script>
export default {
    name: 'GraphicVoteScore',
    data: () => ({
        canvas: undefined,
        context: undefined,
        line: 0,
        lineHeight: 16,
        lineMargin: 3,
        margin: 5,
        painting: false
    }),
    mounted() {
        this.canvas = this.$refs.canvas;
        this.canvas.width = 300;
        this.canvas.height = 400;
        this.context = this.canvas.getContext('2d');
        this.reset();
    },
    methods: {
        reset(lineCount) {
            this.painting = true;
            this.canvas.height = lineCount * this.lineMargin + lineCount * this.lineHeight;
            this.context.fillStyle = 'white';
            this.context.fillRect(0,0, this.canvas.width, this.canvas.height);
            this.line = 0;
        },
        render(type, data) {
            const lineStart = this.line * this.lineHeight + this.line * this.lineMargin;
            //this.canvas.height += this.lineHeight;
            this.line++;
            switch(type) {
                case 'headline':
                    this.context.fillStyle = 'black'
                    this.context.font = `${this.lineHeight}px Arial`;
                    this.context.fillText(data.text, this.margin, lineStart + this.lineHeight);
                    break;
                case 'team':
                    this.context.fillStyle = data.color;
                    this.context.fillRect(0, lineStart, this.canvas.width, this.lineHeight);
                    this.context.fillStyle = 'black';
                    this.context.font = `${this.lineHeight - 6}px Arial`;
                    this.context.fillText(data.name + ' (' + data.points + ' Punkte)', this.margin, lineStart + this.lineHeight - 3);
                    break;
                case 'player':
                    this.context.fillStyle = 'black';
                    this.context.font = `${this.lineHeight - 6}px Arial`;
                    console.log(data)
                    this.context.fillText('- ' + data.name + ' (' + data.score + ' Punkte)', this.margin*2, lineStart + this.lineHeight - 3);
                    break;
            }
        },
        done() {
            this.painting = false;
        },
        getImageData() {
            return this.canvas.toDataURL('image/png');
        }
    }
}
</script>

<style scoped>
canvas {
    padding: 1rem;
    border: 2px solid black;
}
</style>