<template>
  <div class="bg bg-1">
    <NavBar :title="$t('title')">
      <b-btn variant="success" @click="$parent.showUserProfile = false"
        ><b-icon-house-door /> Startseite</b-btn
      >
    </NavBar>
    <b-row class="mt-5">
      <b-card class="form-content mx-auto">
        <b-tabs>
          <b-tab :title="$t('login')">
            <LoginForm />
          </b-tab>
          <b-tab :title="$t('register')">
            <RegisterForm />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-row>
    <b-row class="mt-4">
      <div class="mx-auto">
        <AccountAdvantages />
      </div>
    </b-row>
  </div>
</template>

<script>
import NavBar from "../components/generic/NavBar";
import LoginForm from "../components/login/LoginForm";
import RegisterForm from "../components/login/RegisterForm";
import AccountAdvantages from "../components/login/AccountAdvantages.vue";

export default {
  name: "LoginPage",
  components: {
    NavBar,
    LoginForm,
    RegisterForm,
    AccountAdvantages,
  },
};
</script>

<style scoped>
.form-content {
  width: 95vw;
  max-width: 600px;
}
</style>