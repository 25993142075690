<template>
  <div class="ping-view">
        <b-icon-reception-0 v-if="ping>500" />
        <b-icon-reception-1 v-if="ping>200" />  
        <b-icon-reception-2 v-if="ping>100" /> 
        <b-icon-reception-3 v-if="ping>50" />  
        <b-icon-reception-4 v-if="ping<=50" /> 
      <div>{{ping}} ms</div>
  </div>
</template>

<script>
export default {
  name: "PingView",
  props: ["ping"],
};
</script>

<style scoped>
.ping-view {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.2em;
  pointer-events: none;
  color: white;
  display: flex;
  transform: scale(.8);
  display: none; /**hidden */
}
.ping-view > div {
    margin-left: .5em;
}
</style>
