<template>
  <b-card tag="article" style="max-width: 30rem" class="mb-2">
    <ForgotPasswordDialog ref="forgotPasswordDialog" />
    <DeleteAccountDialog ref="deleteAccountDialog" />
    <div class="d-flex" style="justify-content: space-between">
      <div class="d-flex">
        <LevelBadge :large="true" :points="profileData.points" class="mr-2" />
        <div>
          <h4>{{ profileData.name }}</h4>
          <h5 class="text-muted">{{ profileData.mail }}</h5>
        </div>
      </div>
      <ProfileSettingsDropdown
        @deleteAccount="$refs.deleteAccountDialog.show()"
      />
    </div>
    <ProfileImageDisplay
      :image="profileData.image"
      class="mb-2"
      v-if="profileData.image !== undefined"
    />
    <ChangeImageDialog
      v-if="profileData.image !== undefined"
      :currentImage="profileData.image"
      ref="changeImageDialog"
    />
    <b-list-group>
      <b-list-group-item href="#" @click="$refs.changeImageDialog.show()"
        ><b-icon-image /> {{ $t("change_image") }}</b-list-group-item
      >
      <b-list-group-item href="#" @click="$refs.forgotPasswordDialog.show()"
        ><b-icon-lock /> {{ $t("change_password") }}
      </b-list-group-item>
    </b-list-group>
    <b-list-group class="mt-2">
      <b-list-group-item href="#" @click="createGame()" class="text-success"
        ><b-icon-plus /> {{ $t("create_game") }}
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import ProfileImageDisplay from "./ProfileImageDisplay";
import ChangeImageDialog from "./ChangeImageDialog";
import LevelBadge from "../stats/LevelBadge";
import ForgotPasswordDialog from "../login/ForgotPasswordDialog.vue";
import ProfileSettingsDropdown from "./ProfileSettingsDropdown.vue";
import DeleteAccountDialog from "./DeleteAccountDialog.vue";

export default {
  name: "ProfileDisplay",
  props: ["profileData"],
  components: {
    ProfileImageDisplay,
    ChangeImageDialog,
    LevelBadge,
    ForgotPasswordDialog,
    ProfileSettingsDropdown,
    DeleteAccountDialog,
  },
  created() {
    console.log(this.profileData);
  },
  methods: {
    createGame() {
      this.$emit("createGame");
    },
  },
};
</script>