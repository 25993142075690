<template>
  <div>
    <template v-if="saved">
        <div class="text-success">
            <b-icon-emoji-smile /> {{ $t('thanks_feedback') }}
        </div>
    </template>
    <template v-else>
      <b-form-textarea
        id="textarea"
        v-model="message"
        :placeholder="$t('feedback_text')"
        rows="6"
        max-rows="20"
      ></b-form-textarea>
      <b-form-checkbox
        id="checkbox-answer"
        v-model="wantAnswer"
        name="checkbox-answer"
      >
        {{ $t("want_answer") }}
      </b-form-checkbox>
      <b-form-input
        v-model="mail"
        v-if="wantAnswer"
        :placeholder="$t('mail_address')"
        class="mb-1"
      ></b-form-input>
      <b-button
        variant="success"
        block
        @click="send()"
        :disabled="message.length < 2"
      >
        <b-icon-envelope /> {{ $t("send") }}
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  props: ["game"],
  data: () => {
    return {
      message: "",
      wantAnswer: false,
      mail: "",
      saved: false,
    };
  },
  methods: {
    send() {
      this.game.sendFeedback(
        this.message,
        this.wantAnswer ? this.mail : undefined
      );
      this.saved = true;
    },
  },
};
</script>

<style scoped>
</style>