<template>
    <avataaars
        :accessoriesType="avatar.accessoriesType"
        :clotheType="avatar.clotheType"
        :clotheColor="avatar.clotheColor"
        :eyebrowType="avatar.eyebrowType"
        :eyeType="avatar.eyeType"
        :facialHairColor="avatar.facialHairColor"
        :facialHairType="avatar.facialHairType"
        :graphicType="avatar.graphicType"
        :hairColor="avatar.hairColor"
        :mouthType="avatar.mouthType"
        :skinColor="avatar.skinColor"
        :topType="avatar.topType"
        :topColor="avatar.topColor"
        :isCircle="circle"
      >
      </avataaars>
</template>

<script>
import Avataaars from "vuejs-avataaars";
export default {
    name: 'AvatarDisplay',
    props: ['avatar', 'circle'],
    components: {
        Avataaars
    }
}
</script>