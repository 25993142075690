<template>
  <b-modal ref="modal" :title="$t('join')">
    <qrcode-vue class="hcenter" style="width: fit-content;" :value="content" size="300"/>
    <p class="text-muted mt-2">
        {{ $t('qr_help') }}
    </p>
    <template #modal-footer>
        <b-button variant="primary" small @click="hide()">
            {{ $t('close') }}
        </b-button>
    </template>
  </b-modal>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
  name: "QRModal",
  props: ["content"],
  components: {
      QrcodeVue
  },
  methods: {
      show() {
          this.$refs.modal.show();
      },
      hide() {
          this.$refs.modal.hide();
      }
  }
};
</script>