<template>
  <div class="card">
      <div class="card-header" @click="toggle()">
          <h5>{{header}}</h5>
          <b-button variant="icon" class="toggle-button">
                <b-icon-caret-down class="toggle-icon" :class="{'toggle-icon-open': !collapsed}"/>
          </b-button>   
      </div>
      <div class="card-body" :class="{'body-collapsed': collapsed}">
          <slot />
      </div>
  </div>
</template>

<script>

export default {
  name: "CollapsePanel",
  props: ['header', 'collapsedDefault'],
  data: () => {
      return {
          collapsed: true
      }
  },
  created() {
      this.collapsed = this.collapsedDefault !== undefined ? this.collapsedDefault : true;
      console.log(this.header, this.collapsed);
  },
  methods: {
      toggle() {
          this.collapsed = !this.collapsed;
          if(this.collapsed) {
              this.$emit('collapse');
          } else {
              this.$emit('open');
          }
      },
      collapse() {
          this.collapsed = true;
      }
  }
};
</script>

<style scoped>
.card-body {
    overflow: hidden;
    transition: 200ms;
}
.body-collapsed {
    height: 0;
    padding: 0;
}
.toggle-button {
    position: absolute;
    right: 1em;
    top: .5em;
}
.toggle-icon {
    transition: 200ms;
}
.toggle-icon-open {
    transform: rotate(180deg);
}
</style>
