var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer",class:{ 'outer-selected': _vm.selected }},[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center main-item py-0 px-2",class:{
      'main-item-selected': _vm.selected,
      'main-item-clickable': _vm.game.playerProfile.admin,
    },style:({ background: _vm.backgroundColor }),on:{"click":function($event){return _vm.toggleSelect()}}},[_c('b-row',{staticClass:"mx-0"},[_c('ProfileImageDisplay',{staticClass:"my-1",attrs:{"image":_vm.player.userInfo.image,"size":"icon","hoverable":true,"player":_vm.player}}),(!_vm.loggedIn && _vm.player.id === _vm.game.playerId)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"icon","title":"Avatar ändern"},on:{"click":function($event){return _vm.$emit('editAvatar', _vm.player.userInfo.image.avatar)}}},[_c('b-icon-pencil')],1):_vm._e(),_c('div',{staticClass:"my-auto ml-2",class:{
          'text-muted': _vm.player.conn !== 'on',
          'label-selected': _vm.selected,
        }},[_vm._v(" "+_vm._s(_vm.getPlayerName(_vm.player))+" ")])],1),_c('div',{staticClass:"d-flex"},[(_vm.player.userInfo.points)?_c('LevelBadge',{staticClass:"mr-1",attrs:{"points":_vm.player.userInfo.points,"badge":true}}):_vm._e(),(_vm.player.admin)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"success","title":_vm.$t('host'),"classs":"mx-1","pill":""}},[_c('b-icon-award')],1):_vm._e(),(_vm.player.id === _vm.game.playerId)?_c('b-badge',{staticClass:"mx-1",attrs:{"variant":"info","pill":""}},[_vm._v(_vm._s(_vm.$t("you")))]):_vm._e(),(_vm.player.conn === 'off')?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":"secondary","title":_vm.$t('offline'),"pill":""}},[_c('b-icon-x')],1):_vm._e(),(_vm.player.conn === 'kick')?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":"danger","title":_vm.$t('kicked'),"pill":""}},[_c('b-icon-x')],1):_vm._e(),(
          _vm.game.playerProfile.admin &&
          _vm.player.id !== _vm.game.playerId &&
          _vm.player.conn === 'on'
        )?_c('b-button',{staticClass:"py-0",attrs:{"variant":"icon"}},[_c('b-icon-three-dots-vertical')],1):_vm._e()],1)],1),(_vm.selected)?_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center actions"},[_c('b-row',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm","disabled":_vm.player.admin || _vm.player.conn !== 'on'},on:{"click":function($event){return _vm.kick()}}},[_c('b-icon-x'),_vm._v(" "+_vm._s(_vm.$t("kick"))+" ")],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info","size":"sm","disabled":_vm.player.admin || _vm.player.conn !== 'on'},on:{"click":function($event){return _vm.makeAdmin()}}},[_c('b-icon-award'),_vm._v(" "+_vm._s(_vm.$t("make_admin"))+" ")],1),(_vm.game.settings.teamsEnabled)?_c('TeamSelector',{attrs:{"game":_vm.game,"size":"sm"},on:{"select":function($event){return _vm.selectTeam($event)}}}):_vm._e()],1)],1):_vm._e(),_c('ProfileImageSelector',{attrs:{"target":"player-profile-image","game":_vm.game},on:{"select":function($event){return _vm.onSelectAvatar($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }