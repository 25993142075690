<template>
  <div class="word-selector">
    <WordCatalog
      :game="game"
      ref="word-catalog"
      @select="addFromCatalog($event)"
    />
    <b> {{ $t("selected") }} ({{ game.settings.words.length }}) </b>
    <div class="row mb-4">
      <span v-for="word of game.settings.words" :key="word" class="list-entry">
        <b-badge
          variant="success"
          v-if="canAdd"
          class="added-word-perm"
          @click="deselect(word)"
          >&nbsp;{{ word }} <span class="text-danger">X</span></b-badge
        >
        <b-badge variant="info" v-else>&nbsp;{{ word }}</b-badge>
      </span>
    </div>
    <template v-if="canAdd">
      <b> {{ $t("suggestions") }} </b>
      <div class="row mb-2">
        <span v-for="word of defaultSuggestions" :key="word" class="list-entry">
          <b-badge variant="info" @click="select(word)" class="added-word-perm"
            >{{ word }} <span>+</span></b-badge
          >
        </span>
        <span class="list-entry">
          <b-badge
            variant="success"
            class="added-word-perm"
            @click="showWordCatalog()"
          >
            {{ $t("more_suggestions") }}
          </b-badge>
        </span>
      </div>
      <b-input-group>
        <b-form-input
          v-model="wordInput"
          @keydown="addWordKeydown($event)"
          :placeholder="$t('own_category') + '...'"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="outline-success"
            :disabled="!ownWordValid"
            @click="addOwnWord()"
            ><b-icon-plus /> {{ $t("add") }}</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </template>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import WordCatalog from "./WordCatalog";
export default {
  name: "WordSelector",
  props: ["game"],
  components: {
    WordCatalog,
  },
  data: () => {
    return {
      default_words: {
        en: [
          "City",
          "Country",
          "River",
          "Cause of death",
          "Instrument",
          "Name",
          "Plant species",
          "Color",
          "Food",
          "Drink",
          "Movie",
          "Videogame",
          "Job",
          "Body part",
          "Hobby",
          "Feeling",
          "Disease",
          "Celebrity",
          "Politican",
          "Animal",
          "Swear word",
          "Sport",
          "Reason for divorce",
        ],
        de: [
          "Stadt",
          "Land",
          "Fluss",
          "Todesursache",
          "Instrument",
          "Name",
          "Pflanze",
          "Farbe",
          "Lebensmittel",
          "Getränk",
          "Film",
          "Computerspiel",
          "Beruf",
          "Körperteil",
          "Hobby",
          "Gefühl",
          "Krankheit",
          "Promi",
          "Politiker",
          "Tier",
          "Schimpfwort",
          "Sportart",
          "Scheidungsgrund",
        ],
      },
      suggested_words: {
        de: {
          A: ["Auf dem Bauernhof", "Anmachspruch"],
          C: ["Chemisches Element", "Cocktail", "Computerspiel"],
          D: ["Darf im Kühlschrank nie fehlen", "Dinoart"],
          E: [
            "Erhöht die Coolness",
            "Erfundener Beruf",
            "Etwas ekeliges",
            "Eissorte",
            "Englisches Wort",
            "Elektrisches Gerät",
          ],
          F: ["Fabelwesen", "Farbe", "Fortbewegungsmittel"],
          G: [
            "Grabsteininschrift",
            "Größer als ein Elefant",
            "Grund für Verspätung",
            "Grund zum Heulen",
            "Grund für Schulverweis",
            "Gegenstand auf dem Schreibtisch",
          ],
          K: ["Kleiner als eine Ameise", "Krankheit"],
          L: ["Letzte Worte"],
          N: [
            "Namen den man keinem Kind geben sollte",
            "No-Go beim ersten Date",
            "Naturkatastrophe",
          ],
          P: ["Pizzabelag"],
          R: ["Rappername"],
          S: ["Senkt die Coolness", "Sehenswürdigkeit", "Superkraft"],
          T: ["Tanzart", "Tut weh"],
          W: ["Werbeslogan", "Wort aus einer Fremdsprache"],
        },
        en: {
          A: ["Advertising slogan"],
          B: ["Bigger than an elephant"],
          C: ["Chemical element", "Cocktail"],
          D: ["Dance style"],
          E: ["Electric device"],
          F: ["Foreign word"],
          G: ["Gravestone inscription"],
          H: ["Hurts"],
          I: ["Increases coolness", "Imaginary job", "Ice cream"],
          L: ["Last words", "Lowers the coolness"],
          N: [
            "Names that shouldn't be given to a child",
            "No-go on the first date",
            "Natural disaster",
          ],
          M: [
            "Must never be missing in the refrigerator",
            "Means of transportation",
            "Mythical creature",
          ],
          O: ["On the farm", "On the desk"],
          P: ["Pizza topping", "Pick-up line"],
          R: [
            "Rapper name",
            "Reason for delay",
            "Reason to cry",
            "Reason for expulsion from school",
          ],
          S: [
            "Landmark",
            "Superpower",
            "Something gross",
            "Smaller than an ant",
          ],
        },
      },
      wordInput: "",
    };
  },
  computed: {
    defaultSuggestions() {
      const list = [];
      for (let word of this.default_words[i18n.locale]) {
        if (!this.game.settings.words.includes(word)) {
          list.push(word);
        }
      }
      return list;
    },
    canAdd() {
      return (this.game.playerProfile.admin || this.game.settings.words_free);
    },
    ownWordValid() {
      return (
        this.wordInput.length >= 2 &&
        !this.game.settings.words.includes(this.wordInput)
      );
    },
  },
  methods: {
    deselect(word) {
      this.game.removeWord(word);
    },
    select(word) {
      this.game.addWord(word);
    },
    addWordKeydown(event) {
      if (event.key === "Enter" && this.ownWordValid) {
        this.addOwnWord();
      }
    },
    addOwnWord() {
      this.game.addWord(this.wordInput);
      this.wordInput = "";
    },
    showWordCatalog() {
      this.$refs["word-catalog"].show();
    },
    addFromCatalog(word) {
      if (!this.game.settings.words.includes(word)) {
        this.select(word);
      }
      this.$refs["word-catalog"].hide();
    },
  },
};
</script>

<style scoped>
.added-word-perm {
  margin: 0.1em;
  cursor: pointer;
}
.list-entry {
  margin: 0.05em;
}
.added-word-perm > span {
  opacity: 0;
  transition: 100ms;
}
.added-word-perm:hover > span {
  opacity: 1;
}
</style>
