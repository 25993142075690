<template>
  <b-card :title="$t('why_account')" style="max-width: 600px;">
    <template v-if="german">
      <div class="aspect">
        <b-icon-person-bounding-box class="icon text-success" />
        <div>
          <h5>Eigenes Profilbild</h5>
          <p>Erstelle deinen eigenen Avatar oder lade ein eigenes Bild hoch.</p>
        </div>
      </div>
      <div class="aspect">
        <b-icon-trophy class="icon text-success" />
        <div>
          <h5>Level</h5>
          <p>
            Dein Level steigt mit jedem Punkt den du in einem Spiel machst und
            wird in der Spielerliste neben deinem Namen angezeigt.
          </p>
        </div>
      </div>
      <div class="aspect">
        <b-icon-bar-chart class="icon text-success" />
        <div>
          <h5>Spielstatistik</h5>
          <p>Sieh den Verlauf und Erfolg deiner letzten Spiele.</p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="aspect">
        <b-icon-person-bounding-box class="icon text-success" />
        <div>
          <h5>Own profile image</h5>
          <p>Create your avatar or upload your own image.</p>
        </div>
      </div>
      <div class="aspect">
        <b-icon-trophy class="icon text-success" />
        <div>
          <h5>Level</h5>
          <p>
            Your level increases when your earn points in games and is displayed in the playerlist.
          </p>
        </div>
      </div>
      <div class="aspect">
        <b-icon-bar-chart class="icon text-success" />
        <div>
          <h5>Game stats</h5>
          <p>See your game history and statistics.</p>
        </div>
      </div>     
    </template>
  </b-card>
</template>

<script>
export default {
  name: "AccountAdvantages",
  computed: {
    german() {
      return this.$i18n.locale === "de";
    },
  },
};
</script>

<style scoped>
.aspect {
    display: flex;
}
.aspect .icon {
    font-size: 2rem;
    margin-right: .5rem;
    margin-top: auto;
    margin-bottom: auto;
}
</style>